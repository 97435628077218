import React from "react";
import LogOutHeader from "./LogOutHeader";
import { Fragment } from "react";

function Header() {
    return (
        <Fragment>
            <LogOutHeader />
        </Fragment>
    )
}

export default Header;