export const checkPosting = (data, inputData) => {
    // console.log("data", data);
    // console.log("inputData ", inputData);
    if (data?.product_name === "") {
        alert("홍보하실 상품명 / 브랜드명 / 서비스명을 입력해 주세요.");
        return true;
    } else if (data?.campaign_product_pictures?.length == 0) {
        alert("캠페인 대표 이미지를 업로드해주세요.");
        return true;
    } else if (data?.campaign_postingperiod?.length !== 2) {
        alert("모집 기간을 알려주세요.");
        return true;
    } else if (data?.gender == "" || data?.agecondition?.length == 0 || data?.campaign_regioncondition?.length === 0) {
        alert("프리미언(인플루언서) 조건을 알려주세요.");
        return true;
    } else if (data?.campaign_name === "") {
        alert("캠페인 제목을 입력해 주세요.");
        return true;
    } else if (data?.introduction === "") {
        alert("캠페인을 자유롭게 소개해 주세요.");
        return true;
    }

    // 상품 리뷰
    if (data?.campaign_type === "productReview") {
        if (data?.sponsor_method?.sponsor_method == "retrieval" && data?.campaign_address == "") {
            alert("제품 협찬 방법을 알려주세요");
            return true;
        } else {
            return false;
        }
    }
    // 방문리뷰
    if (data?.campaign_type !== "visitReview") {
        if (data?.additional_influencer_info === "") {
            alert("프리미언에게 제공할 사항을 자세히 알려주세요.");
            return true;
        } else if (data?.sponsor_method?.campaign_address === "") {
            alert("프리미언이 방문할 장소의 정확한 주소를 알려주세요.");
            return true;
        } else if (data?.sponsor_method?.visit_message === "") {
            alert("프리미언이 방문할 수 있는 날짜와 시간, 방문시 유의사항을 자세히 알려주세요.");
            return true;
        }
    }

    // else if (data?.campaign_type === "visitReview") {
    // if(data?.additional_influencer_info)
    // }
};

// 상품 - 협찬하는 상품/서비스 자세히 알려주세요.
// (상품/서비스인데 서비)

// 기본정보 탭
export const checkBasicInfo = (data) => {};
