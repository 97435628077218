import React from "react";

function AuthTimer({ minutes, seconds, timeState, className }) {
    let iseconds = parseInt(seconds);

    if (timeState === true) {
        return (
            <p className={className}>
                {minutes}:{iseconds < 10 ? "0" + iseconds : iseconds}
            </p>
        );
    } else {
        return <p className={className}></p>;
    }
}

export default AuthTimer;
