import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AxiosPost from "components/untils/AxiosPost";

function CampaignPaymentDone() {
    const loginSeq = useSelector((state) => state.auth.seq);
    const [userName, setUserName] = useState(null);
    useEffect(() => {
        AxiosPost("client/user/info", { client_id: loginSeq }).then((res) => {
            setUserName(res?.data?.data?.rows?.manager_name);
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="campaign_pymt_done mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 결제 및 등록이 완료되었습니다.</h2>

                    <div className="findresults mb-5">
                        <p>
                            빅데이터 기반의 더프리미언 AI 알고리즘을 통해<br /> {userName}님의 브랜드 및 서비스에 가장 적합한 인플루언서를 추천해 드리겠습니다.
                            <br />< br />
                            등록한 캠페인의 수정을 원하시거나, 실시간 진행 상황은<br /> ‘마이 캠페인’에서 확인하실 수 있습니다.
                        </p>

                        <p className="mt-4">감사합니다.</p>

                        <div className="col-sm-12 text-center">
                            <Link to="/mycampaign" className="btn_layout">
                                마이캠페인
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CampaignPaymentDone;
