export const checkBoxDatas = [
    { id: 1, label: "패션·미용" },
    { id: 2, label: "맛집" },
    { id: 3, label: "취미·여가·여행" },
    { id: 4, label: "인테리어·DIY" },
    { id: 5, label: "스타·연예인" },
    { id: 6, label: "육아·결혼" },
    { id: 7, label: "IT·컴퓨터" },
    { id: 8, label: "자동차" },
    { id: 9, label: "건강·의학" },
    { id: 10, label: "반려동물" },
    { id: 11, label: "요리·레시피" },
    { id: 12, label: "스포츠" },
    { id: 13, label: "핫플레이스" },
    { id: 14, label: "게임" },
    { id: 15, label: "엔터테인먼트·예술" },
    { id: 16, label: "문학·책" },
    { id: 17, label: "영화" },
    { id: 18, label: "미술·디자인" },
    { id: 19, label: "공연·전시" },
    { id: 20, label: "음악" },
    { id: 21, label: "드라마" },
    { id: 22, label: "만화·애니" },
    { id: 23, label: "방송" },
    { id: 24, label: "생활·노하우·쇼핑" },
    { id: 25, label: "취미" },
    { id: 26, label: "일상·생각" },
    { id: 27, label: "좋은글·이미지" },
    { id: 28, label: "상품리뷰" },
    { id: 29, label: "원예·재배" },
    { id: 30, label: "사진" },
    { id: 31, label: "국내여행" },
    { id: 32, label: "세계여행" },
    { id: 33, label: "지식·동향" },
    { id: 34, label: "사회·정치" },
    { id: 35, label: "비즈니스·경제" },
    { id: 36, label: "어학·외국어" },
    { id: 37, label: "교육·학문" },
];
