import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/header_logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCookie, getCookie, removeCookie } from "components/untils/Cookie";
import { useDispatch } from "react-redux";
import { setLogin } from "redux/store/userSlice";
import { ErrorCodeList } from "components/common/errorCodeList";

function LoginModal({ show, pathname, handleClose }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [failLogin, setFailLogin] = useState(false);
    const [remember, setRemember] = useState(false);
    const [userId, setUserId] = useState("");

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty, isValid },
    } = useForm({ mode: "onSubmit" });

    // 아이디 기억하기 onChange
    const rememberOnChange = (e) => {
        setRemember(e.target.checked);
        if (!e.target.checked) {
            removeCookie("clientId");
            setUserId("");
        }
    };

    const onSubmit = async (data) => {
        await axios
            .post(process.env.REACT_APP_API + "client/user/login", {
                userId: userId ? userId : data.userEmail,
                password: data.userPwd,
            })
            .then((res) => {
                if (res?.data?.result === "success") {
                    setCookie("token", res.data.data.token, { maxAge: 60 * 60 * 6 });
                    setCookie("clientId", data.userEmail, { maxAge: 60 * 60 * 24 * 1000 * 15 });
                    dispatch(setLogin(res.data.data));
                    setFailLogin(false);
                    // navigate("/campaignestimate");

                    handleClose();
                    reset();
                } else {
                    // handleClose();

                    setFailLogin(true);
                    alert(ErrorCodeList.msg601);
                    // window.location.reload(false);
                }
            })
            .then(() => {
                if (pathname) {
                    navigate(pathname);
                    // window.location.href = "/campaignestimate";
                } else {
                    navigate("/mycampaign");
                }
            })
            .catch((error) => {
                alert(`에러가 발생하였습니다.\n잠시후 다시 시도해주세요.`);
                console.error(error);
            });
    };
    const onError = (data) => {
        console.log(data);
    };

    const enterPage = (path) => {
        if (path === "findid") {
            navigate("/find", {
                state: { eventKey: "findid" },
            });
            handleClose();
        } else if (path === "findpwd") {
            navigate("/find", {
                state: { eventKey: "findpwd" },
            });
            handleClose();
        } else if (path === "join") {
            navigate("/join");
            handleClose();
        }
    };

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        let cookieId = getCookie("clientId");
        // console.log("cookieId ---- ", cookieId);
        /*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
        if (cookieId !== undefined) {
            setUserId(cookieId);
            setRemember(true);
        } else {
            // console.log("==", cookieId);
        }
    }, [remember]);

    return (
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered className="login_modal  modal-lg">
            <Modal.Body>
                <div className="popup_logo">
                    <Link to="/">
                        <img src={Logo} alt="The Premian" />
                    </Link>
                </div>

                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    {/*  <!-- Email input --> */}
                    <div className="form-outline mb-3">
                        <input
                            type="text"
                            name="userEmail"
                            id="form2Example1"
                            value={userId}
                            className="form-control"
                            placeholder="이메일"
                            {...register("userEmail", {
                                onChange: (e) => setUserId(e.target.value),
                                // required: "이메일은 필수입니다.",
                                minLength: {
                                    value: 1,
                                    message: "이메일은 필수입니다.",
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                    message: "올바른 이메일 형식으로 입력해 주세요.",
                                },
                            })}
                        />
                        {errors.userEmail && <span className="form-text text-danger">{errors.userEmail.message} </span>}
                        {failLogin && <span className="form-text text-danger"> ID(이메일) 혹은 비밀번호를 다시 확인해 주세요. </span>}
                    </div>

                    {/* <!-- Password input -->*/}
                    <div className="form-outline mb-4">
                        <input
                            type="password"
                            name="userPwd"
                            id="form2Example2"
                            className="form-control"
                            placeholder="비밀번호 "
                            {...register("userPwd", {
                                required: "비밀번호는 필수입니다.",
                                pattern: {
                                    value: /^[a-zA-Z0-9]*$/,
                                    message: "영문, 숫자, 특수문자를 조합하여 주세요.",
                                },
                                minLength: {
                                    value: 6,
                                    message: "6자리 이상 입력하여 주세요.",
                                },
                            })}
                        />
                        {errors.userPwd && <span className="form-text text-danger">{errors.userPwd.message}</span>}
                    </div>

                    {/* <!-- 2 column grid layout for inline styling -->*/}
                    <div className="row mb-4">
                        <div className="col d-flex">
                            {/*    <!-- Checkbox --> */}
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="form2Example31" onChange={(e) => rememberOnChange(e)} checked={remember} />
                                <label className="form-check-label" htmlFor="form2Example31">
                                    아이디 기억하기
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Submit button --> */}
                    <Button type="submit" className="btn_layout  mb-4 width_100">
                        로그인
                    </Button>

                    <div className="row mb-4 find_pass_id">
                        <div className="col text-end">
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    enterPage("findid");
                                }}
                            >
                                <span onClick={scrollToTop}>이메일 찾기</span>
                            </p>
                        </div>
                        <div className="col">
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    enterPage("findpwd");
                                }}
                            >
                                <span onClick={scrollToTop}>비밀번호 찾기</span>
                            </p>
                        </div>
                    </div>

                    {/*<!-- Register buttons -->*/}
                    <div className="text-center register_btn">
                        <Button
                            onClick={() => {
                                enterPage("join");
                            }}
                            type="button"
                            className="btn_layout  mb-4 width_100"
                        >
                            광고주 즉시 가입
                        </Button>
                        <p className="mb-0 mt-4">&#169; ETHAN&ALICE MARKETING. ALL RIGHTS RESERVED 2023</p>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
