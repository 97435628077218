import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { checkBoxDatas } from "components/db/CategoryCheckBox";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { ErrorCodeList } from "components/common/errorCodeList";
import AxiosPost from "components/untils/AxiosPost";
import { channelList } from "components/db/DataArrayList";
import { addCommas } from "components/common/common";

const CampaignEstimate = () => {
    const loginSeq = useSelector((state) => state.auth.seq);
    const navigate = useNavigate();
    const [campaignType, setCampaignType] = useState(null);
    const [eachCheck, setEachCheck] = useState([]);
    const [channelValue, setChannelValue] = useState(null);
    const [progressType, setProgressType] = useState(null);
    const [countNum, setCountNum] = useState(0);
    const [userName, setUserName] = useState("");
    const [campaignPoint, setCampaignPoint] = useState(""); //
    const [formatValue, setFormValue] = useState("");

    const onCheck = (checked, name, e) => {
        if (checked) {
            setEachCheck([...eachCheck, name]);
        } else if (!checked) {
            setEachCheck(eachCheck.filter((el) => el !== name));
        }
        if (eachCheck.length > 2 && checked === true) {
            alert(ErrorCodeList.msg711);
            setEachCheck(eachCheck.filter((el) => el !== name));
            e.target.value = false;
        }
    };

    const handleForm = (e) => {
        const processProduct1 = document.querySelector(".processProduct1");
        const processRecommended1 = document.querySelector(".processRecommended1");
        const processRecommended2 = document.querySelector(".processRecommended2");
        const processReporter = document.querySelector(".processReporter");
        if (e.target.value === "P") {
            setCountNum(0);
            setCampaignPoint(0);
            processProduct1.style.display = "block";
            processRecommended1.style.display = "none";
            processRecommended2.style.display = "none";
            processReporter.style.display = "none";
            setProgressType("P");
        }
        if (e.target.value === "R") {
            setCountNum(0);
            setCampaignPoint(0);
            processProduct1.style.display = "none";
            processRecommended1.style.display = "block";
            processRecommended2.style.display = "block";
            processReporter.style.display = "none";
            setProgressType("R");
        }
        if (e.target.value === "E") {
            setCountNum(0);
            setCampaignPoint(0);
            processProduct1.style.display = "none";
            processRecommended1.style.display = "none";
            processRecommended2.style.display = "none";
            processReporter.style.display = "block";
            setProgressType("E");
        }
    };

    const handleNumberChange = (e) => {
        const inputValue = e.target.value;
        const parsedInput = parseInt(inputValue, 0);
        const trimmedInput = parsedInput?.toString()?.replace(/^0+/, "");
        setCountNum(trimmedInput !== "" ? parseInt(trimmedInput, 10) : 0);
    };
    const handlePointChange = (e) => {
        // const inputValue = e.target.value;
        // const parsedInput = parseInt(inputValue, 0);
        // const trimmedInput = parsedInput?.toString()?.replace(/^0+/, "");
        // setCampaignPoint(trimmedInput !== "" ? parseInt(trimmedInput, 10) : 0);
        const rawValue = e.target.value?.replace(/,/g, ""); // 쉼표 제거
        const strippedValue = rawValue?.replace(/^0+/, ""); // 앞쪽 0 제거
        setFormValue(Number(strippedValue)?.toLocaleString());
        setCampaignPoint(strippedValue);
    };

    const nextPageBtn = () => {
        if (!campaignType) {
            alert(ErrorCodeList.msg714);
            return false;
        } else if (eachCheck.length === 0) {
            alert(ErrorCodeList.msg715);
            return false;
        } else if (!channelValue) {
            alert(ErrorCodeList.msg716);
            return false;
        } else if (!progressType) {
            alert(ErrorCodeList.msg717);
            return false;
        } else if (countNum <= 0) {
            alert(ErrorCodeList.msg718);
            return false;
        } else if (progressType == "R" && campaignPoint <= 0) {
            alert("포인트를 입력해 주세요.");
            return false;
        } else {
            let jsonData = {
                campaignType: campaignType,
                eachCheck: eachCheck,
                channelValue: channelValue,
                progressType: progressType,
                countNum: countNum,
                point: parseInt(campaignPoint),
            };
            navigate("/campaigncostestimate", {
                state: {
                    data: jsonData,
                },
            });
        }
    };

    useEffect(() => {
        AxiosPost("client/user/info", { client_id: loginSeq })
            .then((res) => {
                setUserName(res?.data?.data?.rows?.manager_name);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="mycampaign mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 예상 견적</h2>

                    <div className="mycampaign_detail">
                        <p className="simple_text text-center mb-5">{userName}님께서 원하는 캠페인 유형을 먼저 선택해 주세요.</p>
                        <div className="row reviewpanel  mb-5">
                            <div className="col-sm-4  mb-3 d-flex align-items-stretch" onClick={() => setCampaignType("productReview")}>
                                <div className="card mycampaign_detailinner" style={{ backgroundColor: campaignType === "productReview" ? "#277ae9" : null, cursor: "pointer" }}>
                                    <input type="radio" className="form-check-input custom_radio" id="radio1" name="optradio" value="option1" />
                                    <label className="form-check-label custom_label" htmlFor="radio1">
                                        <div>
                                            <h5>상품 리뷰</h5>
                                            <p>
                                                프리미언(인플루언서)이 <br />
                                                무료 협찬 제품을 직접 사용해 본 <br /> <b>‘사용 후기’</b> 컨텐츠
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-sm-4  mb-3 d-flex align-items-stretch" onClick={() => setCampaignType("serviceReview")}>
                                <div className="card mycampaign_detailinner" style={{ backgroundColor: campaignType === "serviceReview" ? "#277ae9" : null, cursor: "pointer" }}>
                                    <input type="radio" className="form-check-input custom_radio" id="radio2" name="optradio" value="option2" />
                                    <label className="form-check-label custom_label" htmlFor="radio2">
                                        <div>
                                            <h5>서비스 리뷰</h5>
                                            <p>
                                                프리미언(인플루언서)이 <br />
                                                제품 협찬 없이 서비스를 이용한 <br />
                                                <b>‘서비스 체험 후기’</b> 컨텐츠
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-sm-4 mb-3 d-flex align-items-stretch" onClick={() => setCampaignType("visitReview")}>
                                <div className="card mycampaign_detailinner" style={{ backgroundColor: campaignType === "visitReview" ? "#277ae9" : null, cursor: "pointer" }}>
                                    <input type="radio" className="form-check-input custom_radio" id="radio3" name="optradio" value="option3" />
                                    <label className="form-check-label custom_label" htmlFor="radio3">
                                        <div>
                                            <h5>방문 리뷰</h5>
                                            <p>
                                                프리미언(인플루언서)이 <br /> 매장 또는 행사장에 직접 방문한 <br />
                                                <b>‘방문 후기’</b> 컨텐츠
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="review_small_width ">
                            <div className="container">
                                <div className="review_category">
                                    <p>
                                        <span>01</span> 리뷰 카테고리를 선택해 주세요 (최대 3개 중복 가능)
                                    </p>
                                    <div className="col-sm-12 multi_check_box text-start">
                                        {checkBoxDatas.map((item) => {
                                            return (
                                                <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                    <input
                                                        className="form-check-input custom_radio"
                                                        type="checkbox"
                                                        id={item.label}
                                                        name="category"
                                                        onChange={(e) => onCheck(e.target.checked, item.label, e)}
                                                        checked={eachCheck.includes(item.label) ? true : false}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.label} style={{ cursor: "pointer" }}>
                                                        {item.label}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="review_category influencer_channeld">
                                    <p>
                                        <span>02</span> 프리미언(인플루언서) 채널을 선택해 주세요
                                    </p>
                                    <div className="col-sm-12 text-start">
                                        {channelList.map((item) => {
                                            return (
                                                <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id={item.value}
                                                        name="channel"
                                                        value={item.value}
                                                        disabled={item.disabled}
                                                        onChange={(e) => setChannelValue(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.value}>
                                                        {item.label}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                        <p className="text-danger text-16">*다른 채널들은 추후 진행될 예정이에요!</p>
                                    </div>
                                </div>

                                <div className="review_category campaign_work mb-3">
                                    <p>
                                        <span>03</span> 캠페인 진행 방식
                                    </p>
                                    <div className="row text-start">
                                        <div className="col-sm-10">
                                            {" "}
                                            <div className="form-check form-check-inline  mb-3">
                                                <input type="radio" name="slider" className="form-check-input" value="P" id="processProduct" onChange={handleForm} />
                                                <label htmlFor="processProduct" className="form-check-label slide processProduct">
                                                    상품(서비스)로만 진행할래요
                                                    <br />
                                                    <span></span>
                                                    {/*<span>(프리미언에게 포스팅 진행 추가 포인트가 지급되지 않습니다.)</span>*/}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 processProduct1" style={{ display: "none" }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="0"
                                                value={countNum === 0 ? "" : countNum}
                                                onChange={(e) => handleNumberChange(e)}
                                            />
                                            <p className="d-inline nopeople">명</p>
                                        </div>
                                    </div>

                                    <div className="row text-start">
                                        <div className="col-sm-10">
                                            {" "}
                                            <div className="form-check form-check-inline  mb-3">
                                                <input type="radio" name="slider" className="form-check-input" value="R" id="processRecommended" onChange={handleForm} />

                                                <label htmlFor="processRecommended" className="form-check-label slide processRecommended">
                                                    인플루언서에게 상품(서비스)과 포인트를 지급할래요
                                                    <span className="processRecommended2 " style={{ display: "none" }}>
                                                        <br />
                                                        지급 포인트
                                                        <input
                                                            type="text"
                                                            style={{
                                                                border: "1px solid #ced4ad",
                                                                borderRadius: "0.375rem",
                                                                width: "130px",
                                                                height: "30px",
                                                                padding: "15px",
                                                                margin: "0 10px",
                                                                appearance: "none",
                                                            }}
                                                            value={formatValue}
                                                            // value={formatValue === 0 ? "" : formatValue}
                                                            onChange={(e) => handlePointChange(e)}
                                                            // onBlur={handleInputBlur}
                                                        />
                                                        원
                                                    </span>
                                                    <span className="text-danger d-block mt-1">
                                                        *인플루언서 1인당 지급 포인트를 입력하세요
                                                        <br />
                                                        *등급이 높은 인플루언서에게 별도 푸시 발송
                                                        <br />
                                                        *높은 인플루언서 신청율
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 processRecommended1" style={{ display: "none" }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="0"
                                                value={countNum === 0 ? "" : countNum}
                                                onChange={(e) => handleNumberChange(e)}
                                            />
                                            <p className="d-inline nopeople">명</p>
                                        </div>
                                    </div>

                                    <div className="row text-start">
                                        <div className="col-sm-10">
                                            {" "}
                                            <div className="form-check form-check-inline  mb-3">
                                                <input type="radio" name="slider" className="form-check-input" value="E" id="processReporter" onChange={handleForm} />
                                                <label htmlFor="processReporter" className="form-check-label slide processProduct">
                                                    기자단으로 할게요.
                                                    <br />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 processReporter" style={{ display: "none" }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="0"
                                                value={countNum === 0 ? "" : countNum}
                                                onChange={(e) => handleNumberChange(e)}
                                            />
                                            <p className="d-inline nopeople">명</p>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-sm-12 text-center">
                                            <Button className="cost_btn btn_layout" onClick={() => nextPageBtn()}>
                                                예상 견적 확인하기
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CampaignEstimate;
