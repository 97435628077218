export const removeWhitespace = (str) => {
    return str.replace(/\s+/g, "");
};
export const handleSingleImageChange = (e, setImageData, setImageInfo) => {
    if (e.target.files.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
            setImageData(reader.result);
        };
        setImageInfo(e.target.files[0]);
        reader.readAsDataURL(e.target.files[0]);
    } else {
        setImageData(null);
    }
};

export const handleMultiImageChange = (e, setImageState, setReqImage) => {
    const fileList = e.target.files;
    const imageList = [];
    const maxFiles = 5; // 최대 업로드 가능한 파일 개수
    if (fileList?.length > 5) {
        alert("이미지는 최대 5장만 가능합니다.");
        return false;
    } else {
        for (let i = 0; i < fileList.length && i < maxFiles; i++) {
            const reader = new FileReader();
            reader.onload = () => {
                imageList.push(reader.result);
                if (imageList.length === fileList.length && imageList.length <= maxFiles) {
                    setImageState(imageList);
                }
            };

            reader.readAsDataURL(fileList[i]);
            setReqImage(fileList);
        }
    }
};

export const handleImageChange = (imageInfoArray) => {
    const fileArray = imageInfoArray.map(async (imageInfo) => {
        const blob = await fetch(imageInfo.src).then((response) => response.blob());
        return new File([blob], imageInfo.name, { type: blob.type });
    });
    return fileArray;
};
