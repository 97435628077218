import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { getCookie } from "components/untils/Cookie";
import { useDispatch, useSelector } from "react-redux";
import Authmiddleware from "./route/route";
import { setLogout } from "redux/store/userSlice";
import Analytics from "Analytics";
import {
    JoinPage,
    UserInfo,
    TabPage,
    TabFindResult,
    SettlementDetails,
    JoinPageResult,
    FindPwdResult,
    ContactUs,
    FAQ,
    MyCampaign,
    CampaignEstimate,
    CampaignCostEstimate,
    CampaignPaymentDone,
    RegisterCampaign,
    CampaignDetail,
    CampaignManagement,
    InfluencerProfile,
    InfluencerProfile_new,
    TermOfUse,
    PrivacyPolicy,
    LandingPage,
    TemporayCampaign,
} from "./pages/index";

import Header from "./pages/layout/Header/Header";
import Home from "./pages/Home/Home";
import Footer from "./pages/layout/Footer/Footer";
import ChannelTalk from "pages/layout/ChannelTalk/ChannelTalk";
import { persistor } from "./index";
import "./reset.css";

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = getCookie("token");
        if (token && loggedIn === true) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            dispatch(setLogout());
            persistor.purge();
            // navigate("/");
        }
    }, [navigate]);

    return (
        <>
            <Analytics />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />

                {/* 로그인 안해야 접근 가능 */}
                <Route element={<Authmiddleware isLoggedIn={false} />}>
                    <Route path="/join" element={<JoinPage />} />

                    <Route path="/find" element={<TabPage />} />
                    <Route path="/find/result" element={<TabFindResult />} />
                    <Route path="/pwd/result" element={<FindPwdResult />} />
                </Route>

                {/* 로그인 해야 접근 가능 */}
                <Route element={<Authmiddleware isLoggedIn={true} />}>
                    <Route path="/userInfo" element={<UserInfo />} />
                    <Route path="/join/result" element={<JoinPageResult />} />
                    <Route path="/settlementdetails" element={<SettlementDetails />} />
                    <Route path="/temporaycampaign" element={<TemporayCampaign />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/mycampaign" element={<MyCampaign />} />
                    {/* 캠페인 예상 견적 */}
                    <Route path="/campaignestimate" element={<CampaignEstimate />} />
                    <Route path="/campaigncostestimate" element={<CampaignCostEstimate />} />
                    <Route path="/registerCampaign" element={<RegisterCampaign />} />
                    <Route path="/campaignpymtdone" element={<CampaignPaymentDone />} />
                    <Route path="/campaignDetail/*" element={<CampaignDetail />} />
                    <Route path="/influencermanagement/*" element={<CampaignManagement />} />
                    {/* <Route path="/influencerprofile/*" element={<InfluencerProfile />} /> */}
                    <Route path="/influencerprofile/*" element={<InfluencerProfile_new />} />
                </Route>
                <Route path="/termofuse" element={<TermOfUse />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/downloadapp" element={<LandingPage />} />
            </Routes>
            <Footer />
            <ChannelTalk />
        </>
    );
}

export default App;
