import S3 from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const DescriptionFileSave = async (file, key) => {
    let config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: process.env.REACT_APP_DIR_NAME + key,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };
    if (file) {
        let filename = file.name;
        S3.uploadFile(file, config);
    } else {
        return;
    }
};

// import AWS from "aws-sdk";

// export const DescriptionFileSave = async (file, key) => {
//     let config = {
//         bucketName: process.env.REACT_APP_S3_BUCKET,
//         dirName: process.env.REACT_APP_DIR_NAME + key,
//         region: process.env.REACT_APP_REGION,
//         accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//         secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
//     };

//     AWS.config.update({ region: config.region, accessKeyId: config.accessKeyId, secretAccessKey: config.secretAccessKey });
//     const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: { Bucket: config.bucketName } });

//     let upload_params = { Bucket: config.bucketName, Key: file.path, Body: file };
//     let upload = new AWS.S3.ManagedUpload({ params: upload_params });
//     let promise = upload.promise();
//     promise.then(
//         function (data) {
//             console.log("Successfully uploaded:", file.path);
//         },
//         function (err) {
//             console.log("Failed to upload", file.name, "with error:", err.message);
//         }
//     );
// };
