import React, { Fragment, useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import RegisterBasicInfo from "components/RegisterCampaign/RegisterBasicInfo";
import ServiceReviewBasicInfo from "components/ServiceReviewCampaign/ServiceReviewBasicInfo";
import RegisterMoreInfo from "components/RegisterCampaign/RegisterMoreInfo";
import VisitReviewMoreInfo from "components/VisitReviewCampaign/VisitReviewMoreInfo";
import VisitReviewBasicInfo from "components/VisitReviewCampaign/VisitReviewBasicInfo";
import RegisterPostingGuide from "components/RegisterCampaign/RegisterPostingGuide";
import CheckCampaignRequest from "./CheckCampaignRequest";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const RegisterCampaign = () => {
    let resultsRef = useRef();
    const loginSeq = useSelector((state) => state.auth.seq);
    const location = useLocation();
    const locationData = location?.state?.data;
    const locationPrice = location?.state?.price;
    const campaignNum = location?.state?.campaignNum;
    const [campaignInfo, setCampmaignInfo] = useState(null); // 임시저장 캠페인 정보
    const [campaignPicture, setCampaignPicture] = useState([]); // 캠페인 이미지
    const [activeTab, setActiveTab] = useState("basicinfo");
    const [jsonData, setJsonData] = useState({
        client_id: loginSeq,
        //캠페인 유형 : productReview, serviceReview, visitReview
        campaign_type: locationData?.campaignType,
        // 프리미언(인플루언서) 채널
        campaign_channel: locationData?.channelValue,
        // 캠페인 진행 방식 (명)수
        campaign_counter: locationData?.countNum,
        // 캠페인 진행박식: 상품으로 진행 :processProduct, 추천 프리미언 :processRecommended
        progress_type: locationData?.progressType,
        // 리뷰 카테고리
        product_category: locationData?.eachCheck,
        // 캠페인 포인트
        campaign_point: locationData?.point,
        // 상품명/브랜드명/서비스명
        product_name: "",
        // 캠페인 대표 1장
        campaign_product_pictures: [],
        // 캠페인 이미지 4장
        campaign_images: [],
        // 이미지 프리뷰 db 업로드 필요 x
        campaign_product_preview: [],
        // 설명 이미지 프리뷰 db 업로드 필요 x
        campaign_img_previews: [],
        // 제품 협찬 방법
        sponsor_method: {
            sponsor_method: "",
            discount: "",
            campaign_address: "",
            campaign_address_detail: "",
            // 추가 설명정보
            explanation: "",
            // 유희사항
            visit_message: "",
        },
        // 성별 여성:W, 남성:M, 상관없음:A
        gender_condition: "",
        // 연령대 ** 다른 테이블이 아닌 main 테이블에서 array로 데이터 추가 할 예정
        agecondition: [],
        // 지역
        campaign_regioncondition: [],
        // 캠페인 제목
        campaign_name: "",
        // 협찬하는 상품 자세히
        product_detail: "",
        // sns 링크, 홈페이지
        campaign_url: "",
        // 자유롭게 소개
        introduction: "",
        // 콘텐츠 유형 선택 - 이미지: image, 동영상: video, 중복: all
        contents_type: "",
        // 촬영 방법
        photography_preference: "",
        // 반드시 포함되어야 할 키워드 및 예시
        text_requirements: "",
        // 해시태그
        campaign_hashtag: {
            hashtag1: "",
            hashtag2: "",
            hashtag3: "",
            hashtag4: "",
        },
        // 캠페인 날짜
        campaign_postingperiod: [],
        // 설명과 이미지
        campaign_descriptionfile: {
            image_data: "",
            preview_data: "",
            simple_explanation: "",
        },
        // 인플루언서에게 제공할 사항
        additional_influencer_info: "",
        // 포스팅에 포함되어야 한다는 문구 보이기 checkbox
        include_text: "",
        // 예약필수 텍스트 checkbox
        reservation_text: "",
        campaign_id: "",
        campaign_price: locationPrice,
        needHeader: true,
        tabLevel: "",
    }); // 아래 useEffect에서 정보 가져오고 있음

    const titleType = () => {
        let result = "";
        switch (locationData?.campaignType) {
            case "productReview":
                result = "상품리뷰";
                break;
            case "serviceReview":
                result = "서비스리뷰";
                break;
            case "visitReview":
                result = "방문리뷰";
                break;
            default:
                break;
        }
        return result;
    };

    const selectBasicComponent = () => {
        if (locationData?.campaignType === "productReview" || campaignInfo?.campaign_type === "productReview") {
            return <RegisterBasicInfo setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />;
        } else if (
            locationData?.campaignType === "serviceReview" ||
            campaignInfo?.campaign_type === "serviceReview" ||
            locationData?.campaignType === "visitReview" ||
            campaignInfo?.campaign_type === "visitReview"
        ) {
            return <ServiceReviewBasicInfo setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />;
        }
    };
    const selectMoreComponent = () => {
        if (locationData ? locationData?.campaignType === "visitReview" : campaignInfo?.campaign_type === "visitReview") {
            return <VisitReviewMoreInfo setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />;
        } else {
            return <RegisterMoreInfo setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />;
        }
    };

    const tabs = {
        basicinfo: selectBasicComponent(),
        moreinfo: selectMoreComponent(),
        postguide: <RegisterPostingGuide setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />,
        checkcampaign: <CheckCampaignRequest setActiveTab={setActiveTab} jsonData={jsonData} setJsonData={setJsonData} />,
    };

    const tabClickEvt = (tabName) => {
        if (jsonData?.tabLevel === "postguide" && tabName !== "postguide" && activeTab === "postguide") {
            if (confirm("현재 작성중인 내용이 사라집니다. \n그래도 이동하시겠습니까?")) {
                setActiveTab(tabName);
                return true;
            } else {
                return false;
            }
        } else {
            setActiveTab(tabName);
            return true;
        }
    };

    // 리뷰 타입 구별 탭
    const TabSelect = () => {
        return (
            <ul className="mb-3 nav nav-tabs nav-fill" role="tablist">
                <li className="nav-item">
                    <Button type="button" className={`nav-link ${activeTab === "basicinfo" ? "active" : ""}`} onClick={() => tabClickEvt("basicinfo")}>
                        기본정보
                    </Button>
                </li>

                <li className="nav-item">
                    <Button type="button" className={`nav-link ${activeTab === "moreinfo" ? "active" : ""}`} onClick={() => tabClickEvt("moreinfo")}>
                        상세정보
                    </Button>
                </li>

                <li className="nav-item">
                    <Button type="button" className={`nav-link ${activeTab === "postguide" ? "active" : ""}`} onClick={() => tabClickEvt("postguide")}>
                        포스팅 가이드
                    </Button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        if (resultsRef.current) {
            window.scrollTo({
                behavior: "auto",
                top: resultsRef.current.offsetTop,
            });
        }
    }, [tabs[activeTab]]);

    useEffect(() => {
        if (campaignNum) {
            AxiosPost("webcampaign/getCampaign", { campaign_id: campaignNum })
                .then((res) => {
                    console.log("임시 캠페인 가져오기 --- ", res?.data?.data);
                    if (res?.data?.result === "success") {
                        setCampmaignInfo(res?.data?.data);
                        let getData = res?.data?.data;
                        let dbImageData = getData?.image_data?.split(", ");
                        let dbTitleImg = dbImageData?.length > 1 ? dbImageData?.slice(0, 1) : dbImageData?.length == 0 ? [] : [getData?.image_data];
                        let dbContentImg = dbImageData?.length > 1 ? dbImageData?.slice(1) : [];

                        setJsonData((prevData) => ({
                            ...prevData,
                            client_id: loginSeq,
                            //캠페인 유형 : productReview, serviceReview, visitReview
                            campaign_type: getData?.campaign_type,
                            // 프리미언(인플루언서) 채널
                            campaign_channel: getData?.campaign_sns,
                            // 캠페인 진행 방식 (명)수
                            campaign_counter: getData?.total_count,
                            // 캠페인 진행박식: 상품으로 진행 :processProduct, 추천 프리미언 :processRecommended
                            progress_type: getData?.progress_type,
                            // 리뷰 카테고리
                            product_category: getData?.category?.split(","),
                            // 캠페인 포인트
                            campaign_point: getData?.campaign_point,
                            // 상품명/브랜드명/서비스명
                            product_name: getData?.product_name,
                            // 캠페인 대표 1장
                            campaign_product_pictures: dbTitleImg,
                            // 캠페인 이미지 4장
                            campaign_images: dbContentImg,
                            // 이미지 프리뷰 db 업로드 필요 x
                            campaign_product_preview: dbTitleImg,
                            // 설명 이미지 프리뷰 db 업로드 필요 x
                            campaign_img_previews: dbContentImg,
                            // 제품 협찬 방법
                            sponsor_method: {
                                sponsor_method: getData?.sponsor_method,
                                discount: getData?.discount,
                                campaign_address: getData?.campaign_address,
                                campaign_address_detail: getData?.campaign_address_detail,
                                // 추가 설명정보
                                explanation: getData?.explanation,
                                // 유희사항
                                visit_message: getData?.visit_message,
                            },
                            // 성별 여성:W, 남성:M, 상관없음:A
                            gender_condition: getData?.gender_condition,
                            // 연령대 ** 다른 테이블이 아닌 main 테이블에서 array로 데이터 추가 할 예정
                            agecondition: getData ? getData?.age_condition?.split(",") : [],
                            // 지역
                            campaign_regioncondition: getData ? getData?.region_condition?.split(",") : [],
                            // 캠페인 제목
                            campaign_name: getData?.campaign_name,
                            // 협찬하는 상품 자세히
                            product_detail: getData?.product_detail,
                            // sns 링크, 홈페이지
                            campaign_url: getData?.campaign_url,
                            // 자유롭게 소개
                            introduction: getData?.introduction,
                            // 콘텐츠 유형 선택 - 이미지: image, 동영상: video, 중복: all
                            contents_type: getData?.contents_type,
                            // 촬영 방법
                            photography_preference: getData?.photography_preference,
                            // 반드시 포함되어야 할 키워드 및 예시
                            text_requirements: getData?.text_requirements,
                            // 해시태그
                            campaign_hashtag: {
                                hashtag1: getData?.hashtag1 ? getData?.hashtag1 : "",
                                hashtag2: getData?.hashtag2 ? getData?.hashtag2 : "",
                                hashtag3: getData?.hashtag3 ? getData?.hashtag3 : "",
                                hashtag4: getData?.hashtag4 ? getData?.hashtag4 : "",
                            },
                            // 캠페인 날짜
                            campaign_postingperiod: getData
                                ? [
                                      {
                                          start_date: moment(getData?.influencer_start_date)?.format("YYYY-MM-DD HH:mm:s"),
                                          end_date: moment(getData?.influencer_end_date)?.format("YYYY-MM-DD HH:mm:s"),
                                          period_type: "인플루언서 모집기간",
                                          date_type: "I",
                                      },
                                      {
                                          start_date: moment(getData?.campaign_start_date)?.format("YYYY-MM-DD HH:mm:ss"),
                                          end_date: moment(getData?.campaign_end_date)?.format("YYYY-MM-DD HH:mm:ss"),
                                          period_type: "캠페인 포스팅 기간",
                                          date_type: "P",
                                      },
                                  ]
                                : [],
                            // 설명과 이미지
                            campaign_descriptionfile: {
                                image_data: "",
                                preview_data: "",
                                simple_explanation: "",
                            },
                            // 인플루언서에게 제공할 사항
                            additional_influencer_info: "",
                            // 포스팅에 포함되어야 한다는 문구 보이기 checkbox
                            include_text: getData?.include_text,
                            // 예약필수 텍스트 checkbox
                            reservation_text: getData?.reservation_text,
                            campaign_id: getData?.campaign_id,
                            campaign_price: getData?.campaign_price,
                            needHeader: true,
                            tabLevel: "",
                            active: getData?.active,
                        }));
                    }
                })
                .catch((error) => console.log(error));
        }
    }, []);

    return (
        <Fragment>
            {jsonData?.needHeader && <div className="hr_line"></div>}
            <div className="register_campaign_tab tab_panel mtb190">
                <div className="container" ref={resultsRef}>
                    {jsonData?.needHeader && (
                        <>
                            <h2 className="inner_heading">{titleType()} 캠페인 요청서 작성</h2>
                            <TabSelect />
                        </>
                    )}
                    <div className="tab-content">{tabs[activeTab]}</div>
                </div>
            </div>
        </Fragment>
    );
};

export default RegisterCampaign;
