import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BasicProfile from "../../assets/images/profile_ex.png";
import DummyImg from "../../assets/images/img_dummy_pc_440.jpg";
import BestComments from "../../assets/images/best_comments.png";
import BestLikes from "../../assets/images/best_likes.png";
import BestView from "../../assets/images/best_view.png";
import NewPost from "../../assets/images/new_post.png";
import GrowthGraph from "components/Graph/GrowthGraph";
import AdRateGraph from "components/Graph/AdRateGraph";
import FollowerGenderGraph from "components/Graph/FollowerGenderGraph";
import FollowerBarGraph from "components/Graph/FollowerBarGraph";
import ReelsPieGraph from "components/Graph/ReelsPieGraph";
import ReelsBarGraph from "components/Graph/ReelsBarGraph";
import FollowerRegionBarGraph from "components/Graph/FollowerRegionBarGraph";

import { useLocation } from "react-router";
import moment from "moment";
import RadarChart from "components/Graph/RadarChart";
import AxiosPost from "components/untils/AxiosPost";
import { FatchPost, AxiosPosts } from "components/untils/FatchPost";
import { selectAgeType } from "components/common/SelectData";
import { addCommas, parseInstagramCount, processText, formatInstagramKorean } from "components/common/common";

function InfluencerProfile_new() {
    const location = useLocation();
    const campaignId = location.state.campaignId;
    const influencerId = location.state.influencerId;
    const influDatas = location?.state?.influDatas;
    const getInfo = location?.state?.getInfo;

    const [userInfo, setUserInfo] = useState(null);
    const [updateEffect, setUpdateEffect] = useState(false);
    const [postingList, setPostingList] = useState([]);
    const [instatTotal, setInstaTotal] = useState(null);
    const [followerCity, setFollowerCity] = useState([]);
    const [businessActive, setBusinessActive] = useState("N");

    const handleImgError = (e) => {
        e.target.src = BasicProfile;
    };
    const postingImgError = (e) => {
        e.target.src = DummyImg;
    };

    function updateDrafting(result) {
        AxiosPosts("webcampaign/drafting", {
            campaign_id: campaignId,
            influencer_id: influencerId,
            flag: result,
        }).then((res) => {
            setUpdateEffect(true);
        });
    }

    // 인플루언서 선발 / 취소 버튼
    const influeSelectClick = (selected) => {
        let result = selected === "Y" ? "N" : "Y";
        updateDrafting(result);
    };

    useEffect(() => {
        AxiosPost("webcampaign/campaignInfluId", { campaign_id: campaignId, influencer_id: influencerId })
            .then((res) => {
                // console.log("확인", res);

                let userinfo = res?.data?.data;
                // 팔로워의 지역 순위 json으로 가져오고 있어 가공필요.
                let cityJosn = res?.data?.data?.city_json ? JSON.parse(res?.data?.data?.city_json) : [];
                let cityArray;
                if (cityJosn !== null) {
                    cityArray = Object.entries(cityJosn)?.map(([key, value]) => ({ key: key.split(", ")[0], value }));
                    cityArray?.sort((a, b) => b.value - a.value);
                }

                setFollowerCity(cityArray?.slice(0, 4));
                setUserInfo(userinfo);
                setUpdateEffect(false);
                setBusinessActive(userInfo?.is_business_account);

                // 인플루언서의 post_list 테이블 데이터 가져오기
                function postListAxios() {
                    AxiosPost("webcampaign/instaramPosList", { influencer_id: influencerId }).then((response) => {
                        // console.log("비지니스 x", response?.data);
                        setBusinessActive("N");
                        setPostingList(response?.data?.data?.rows);
                        setInstaTotal(response?.data?.data?.instarData);
                    });
                }
                // db의 날짜가 오늘 날짜와 3일의 차이가 있는지 확인
                function isDateWithThreeDays(dbDate) {
                    const todayDate = moment();
                    const diffInday = todayDate?.diff(dbDate, "days");
                    return diffInday <= 3;
                }

                // 인플루언서의 포스트 리스트 조회수 필요 여부는 비지니스에 따라 다름
                if (res?.data?.data?.is_business_account === "Y") {
                    AxiosPost("webcampaign/instaramPosListBiz", { influencer_id: influencerId }).then((response) => {
                        // 인플루언서가 비지니스 일 경우 최신 c_date가 3일을 경과하지 않았을 경우 true. raw테이블의 최신 c_date값이 3일 이상이면 인스타그램 이미지가 만료되므로.
                        if (isDateWithThreeDays(response?.data?.data?.rows[0]?.c_date)) {
                            // console.log("비지니스 o", response?.data);
                            setBusinessActive("Y");
                            setPostingList(response?.data?.data?.rows);
                            setInstaTotal(response?.data?.data?.instarData);
                        } else {
                            postListAxios();
                        }
                    });
                } else {
                    postListAxios();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [updateEffect]);

    function checkNaN(value) {
        if (isNaN(value)) {
            return 0;
        } else {
            return value;
        }
    }

    // 노출수/도달수 변화 숫자
    function highRow(num) {
        let isNegative = Math.sign(num) === -1;
        // 부호 제외한 정수만 사용
        let absoluteValue = Math.abs(num);
        if (isNegative) {
            return <span className="red_color">{addCommas(absoluteValue)}</span>;
        } else if (Math.sign(num) === 1) {
            return <span className="green_color">{addCommas(num)}</span>;
        } else {
            return null;
        }
    }
    // 팔로워 트렌드텍스트
    function trendText(last, today) {
        let result = parseInt(last) - parseInt(today);
        let absoluteValue = Math.abs(result);
        if (parseInt(last) < parseInt(today)) {
            return (
                <>
                    <span>+{addCommas(absoluteValue)}</span>&nbsp; 늘었어요.
                </>
            );
        } else if (parseInt(last) === parseInt(today)) {
            return <>같아요.</>;
        } else {
            return (
                <>
                    <span>-{addCommas(result)}</span>&nbsp; 줄었어요.
                </>
            );
        }
    }
    // 전날 대비 평균
    function beforeAvar() {
        let value1 = yesterdayAvar(userInfo?.last_month_media_cnt, userInfo?.two_month_ago_media_cnt);
        let value2 = yesterdayAvar(userInfo?.last_month_like_cnt, userInfo?.two_month_ago_like_cnt);
        let value3 = yesterdayAvar(userInfo?.last_month_comment_cnt, userInfo?.two_month_ago_comment_cnt);
        value1 = parseInt(value1);
        value2 = parseInt(value2);
        value3 = parseInt(value3);

        const average = (value1 + value2 + value3) / 3; // 세 값의 평균 계산
        const averageIncrease = average.toFixed(2);

        // 증가 또는 감소 여부에 따라 부호 설정
        const sign = average >= 0 ? "+" : "";

        return sign + averageIncrease;
    }

    // 각각의 전날 대비 평균
    function yesterdayAvar(past, current) {
        // 전날 대비 증가량 계산
        const increase = current - past;
        // 전날 대비 평균 계산
        const averageIncrease = ((increase / past) * 100).toFixed(1);
        return averageIncrease;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="influencer_profile mtb190">
                <div className="container">
                    <h2 className="inner_heading">인플루언서 프로필 및 통계</h2>

                    <div className="influ_prfl_box influ_prfl_box1">
                        <div className="follower_info_new mb_10">
                            <div className="left">
                                <label>기본 프로필 분석</label>
                            </div>

                            <div className="right">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="comn_profl_lyout default_profile">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="user_pic">
                                                        <img
                                                            src={userInfo?.profile_picture_url ? userInfo?.profile_picture_url : BasicProfile}
                                                            onError={handleImgError}
                                                            alt="User Pic"
                                                        />
                                                        <h4 className="mt-3">@{userInfo?.instagram_username}</h4>
                                                        <h4 className="mt-2">{userInfo?.name}</h4>
                                                    </div>

                                                    <div className="row insta_followers_posts">
                                                        <div className="col-sm-6">
                                                            <p>
                                                                <span>{userInfo?.followers_count ? formatInstagramKorean(userInfo?.followers_count) : 0}</span>
                                                            </p>
                                                            <p>팔로워</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p>
                                                                <span>{formatInstagramKorean(userInfo?.media_count)}</span>
                                                            </p>
                                                            <p>포스팅</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 insta_profile_info">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <p>성별</p>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <p>
                                                                <span>{userInfo?.gender}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <p>연령대</p>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <p>
                                                                <span>{selectAgeType(userInfo?.age_group)}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <p>지역</p>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <p>
                                                                <span>{userInfo?.region}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <p>관심사</p>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <p>
                                                                <span>{userInfo?.category}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {userInfo?.most_category && (
                                                        <div className="ai_analist">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <p>AI가 분석한 관심사</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <p>{userInfo?.most_category}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="comn_profl_lyout ai_graph">
                                            <RadarChart userInfo={userInfo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="follower_info_new mb_10">
                        <div className="left"> </div>
                        <div className="right">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="comn_profl_lyout insta_profile_infulation">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <p>노출수</p>
                                                <h2>
                                                    {userInfo?.impressions ? `${addCommas(userInfo?.impressions)} ` : "미공개"} {highRow(userInfo?.impressions_rate)}
                                                </h2>
                                            </div>
                                            <div className="col-sm-6">
                                                <p>도달수</p>
                                                <h2>
                                                    {userInfo?.reach ? `${addCommas(userInfo?.reach)} ` : "미공개"} {highRow(userInfo?.reach_rate)}
                                                </h2>
                                            </div>
                                        </div>
                                        <p className="fontsize14">노출수 / 도달수는 프로페셔널 계정만 보여집니다. </p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="comn_profl_lyout insta_hashtags">
                                        <p>
                                            자주 사용한 키워드 <span>최근 포스팅 분석입니다 </span>
                                        </p>
                                        {userInfo?.recent_keyword !== "" ? (
                                            <ul>
                                                {userInfo?.recent_keyword?.split(/,|\s+/)?.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {/* {item?.substring(0, 13) + ".."} */}
                                                            {/* {truncateString(item, 14)} */}
                                                            {item}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <div>
                                                <p className="text-center" style={{ marginTop: "50px" }}>
                                                    자주 사용한 키워드가 없습니다.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="follower_info_new mb_10 premian_aianalisys">
                        <div className="left">
                            <label>더프리미언 AI 분석</label>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout graph_info">
                                        <div className="tooltip1">
                                            <label>평가 점수</label>
                                            <span className="tooltiptext1 tooltiptext3 tooltiptext7">참여한 캠페인을 광고주가 평가한 점수의 평균입니다.</span>
                                        </div>
                                        <p>
                                            <br />
                                            이전 광고주로부터 평균 <span>{userInfo?.preference_score > 0 ? userInfo?.preference_score : 7}점</span>의 캠페인 수행 점수를 받았어요.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout graph_info">
                                        <div className="tooltip1">
                                            <label>반응률</label>
                                            <span className="tooltiptext1 tooltiptext3 tooltiptext7">최근 포스팅에 반응한 숫자의 합(좋아요/댓글)을 팔로워 수로 나눈 값입니다.</span>
                                        </div>
                                        <p>
                                            <br />
                                            팔로워수 대비 반응은 {userInfo?.response_rate?.toFixed(2)}%로 평균보다{" "}
                                            <span>{userInfo?.response_rate > userInfo?.response_rate_avg ? "높아요" : "낮아요"}</span>.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout graph_info">
                                        <div className="tooltip1">
                                            <label>전문성</label>
                                            <span className="tooltiptext1 tooltiptext3 tooltiptext7">
                                                인플루언서가 선택한 카테고리와 관련된 <br />
                                                키워드를 실제 포스팅에 사용한 횟수입니다{" "}
                                            </span>
                                        </div>
                                        <p>
                                            <br />
                                            관심 카테고리 관련 키워드가 <span>{userInfo?.professional_rate}번</span> 사용되었어요.{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="follower_info_new mb_10 premian_aianalisys premian_aianalisys_graph">
                        <div className="left"></div>
                        <div className="right">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="comn_profl_lyout graph_info">
                                        <div className="tooltip1">
                                            <label>성장률</label>
                                            <span className="tooltiptext1 tooltiptext3 tooltiptext6">전달 대비 포스팅, 좋아요, 댓글 수의 수치로 나타납니다</span>
                                        </div>

                                        {userInfo?.last_month_media_cnt && userInfo?.last_month_like_cnt && userInfo?.last_month_comment_cnt ? (
                                            <>
                                                <p>
                                                    <br />
                                                    계정의 포스팅, 좋아요, 댓글 수가 전달대비 평균 <span>{beforeAvar()}%</span> 에요.
                                                </p>
                                                <ul className="line_graph_new">
                                                    <li>
                                                        <GrowthGraph twoMonth={userInfo?.two_month_ago_media_cnt} lastMonth={userInfo?.last_month_media_cnt} />
                                                        <p>
                                                            {yesterdayAvar(userInfo?.last_month_media_cnt, userInfo?.two_month_ago_media_cnt)}%<span>포스팅</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <GrowthGraph twoMonth={userInfo?.two_month_ago_like_cnt} lastMonth={userInfo?.last_month_like_cnt} />
                                                        <p>
                                                            {yesterdayAvar(userInfo?.last_month_like_cnt, userInfo?.two_month_ago_like_cnt)}%<span>좋아요</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <GrowthGraph twoMonth={userInfo?.two_month_ago_comment_cnt} lastMonth={userInfo?.last_month_comment_cnt} />
                                                        <p>
                                                            {yesterdayAvar(userInfo?.last_month_comment_cnt, userInfo?.two_month_ago_comment_cnt)}%<span>댓글</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </>
                                        ) : (
                                            <div>
                                                <p className="text-center" style={{ marginTop: "100px" }}>
                                                    전달 데이터가 없습니다.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="comn_profl_lyout graph_info">
                                        <div className="tooltip1">
                                            <label>광고율</label>
                                            <span className="tooltiptext1 tooltiptext3 tooltiptext6">포스팅에서 광고, ad, 협찬 등의 키워드를 사용한 비율로 나타납니다. </span>
                                        </div>
                                        <div className="graphfive_new">
                                            <AdRateGraph adRate={userInfo?.ad_rate} />
                                        </div>
                                        <p className="text-center">
                                            포스팅에서 광고, 협찬, AD 키워드를 사용한 비율이 <span>{userInfo?.ad_rate}%</span> 에요.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {businessActive === "Y" && (
                        <div className="follower_info_new mb_10 audience_graphs">
                            <div className="left graph_info">
                                <div className="tooltip1">
                                    <label>오디언스</label>
                                    <span className="tooltiptext1 tooltiptext5">최근 30일간의 데이터 분석 기준입니다.</span>
                                </div>
                            </div>

                            <div className="right">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="comn_profl_lyout">
                                            <p>팔로워 성별</p>
                                            <div className="common_graph graph_two">
                                                <FollowerGenderGraph lady={userInfo?.female} man={userInfo?.male} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="comn_profl_lyout">
                                            <p>팔로워 트렌드</p>
                                            {userInfo?.todayFollowersCnt && userInfo?.lastMonthFollowersCnt ? (
                                                <>
                                                    <div className="common_graph graph_two" style={{ marginBottom: "5px" }}>
                                                        <GrowthGraph twoMonth={userInfo?.todayFollowersCnt} lastMonth={userInfo?.lastMonthFollowersCnt} />
                                                    </div>
                                                    <p className="fontsize14">
                                                        계정의 팔로워 수가 전달대비 <br />
                                                        {trendText(userInfo?.lastMonthFollowersCnt, userInfo?.todayFollowersCnt)}
                                                    </p>
                                                </>
                                            ) : (
                                                <div>
                                                    <p className="text-center" style={{ marginTop: "60px" }}>
                                                        전달 데이터가 없습니다.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="comn_profl_lyout">
                                            <p>팔로워 연령대</p>
                                            <div className="common_graph graph_two">
                                                <FollowerBarGraph
                                                    plus18={userInfo?.plus18}
                                                    plus25={userInfo?.plus25}
                                                    plus35={userInfo?.plus35}
                                                    plus44={userInfo?.plus44}
                                                    plus55={userInfo?.plus55}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="comn_profl_lyout">
                                            <p>지역별 팔로워 비율</p>
                                            {followerCity?.length > 0 ? (
                                                <div className="common_graph graph_two">
                                                    <FollowerRegionBarGraph cityData={followerCity ? followerCity : []} />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p className="text-center" style={{ fontSize: "13px", marginTop: "50px" }}>
                                                        지역별 팔로워 데이터가 없습니다.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="follower_info_new mb_10">
                        <div className="left graph_info">
                            <div className="tooltip1">
                                <label>포스팅</label>
                                <span className="tooltiptext1 tooltiptext5">최근 30일간의 데이터를 바탕에 의한 분석 입니다.</span>
                            </div>
                        </div>

                        <div className="right">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout influ_posting_picss">
                                        <div className="compare_lks_cmts">
                                            <img src={businessActive === "Y" ? BestView : BestLikes} />
                                        </div>
                                        <img
                                            src={postingList && postingList[0]?.media_url !== "except X" ? postingList[0]?.media_url : DummyImg}
                                            alt=""
                                            onError={postingImgError}
                                        />
                                    </div>
                                    <div className="influ_posting_like_comt">
                                        <ul>
                                            <li>{postingList ? formatInstagramKorean(postingList[0]?.like_cnt) : 0}</li>
                                            <li>{postingList ? formatInstagramKorean(postingList[0]?.comment_cnt) : 0}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout influ_posting_picss">
                                        <div className="compare_lks_cmts">
                                            <img src={businessActive === "Y" ? BestLikes : BestComments} />
                                        </div>
                                        <img
                                            src={postingList && postingList[1]?.media_url !== "except X" ? postingList[1]?.media_url : DummyImg}
                                            onError={postingImgError}
                                            alt=""
                                        />
                                    </div>
                                    <div className="influ_posting_like_comt">
                                        <ul>
                                            <li>{postingList ? formatInstagramKorean(postingList[1]?.like_cnt) : 0}</li>
                                            <li>{postingList ? formatInstagramKorean(postingList[1]?.comment_cnt) : 0}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="comn_profl_lyout influ_posting_picss">
                                        <div className="compare_lks_cmts">
                                            <img src={businessActive === "Y" ? BestComments : NewPost} />
                                        </div>
                                        <img
                                            src={postingList && postingList[2]?.media_url !== "except X" ? postingList[2]?.media_url : DummyImg}
                                            onError={postingImgError}
                                            alt=""
                                        />
                                    </div>
                                    <div className="influ_posting_like_comt">
                                        <ul>
                                            <li>{postingList ? formatInstagramKorean(postingList[2]?.like_cnt) : 0}</li>
                                            <li>{postingList ? formatInstagramKorean(postingList[2]?.comment_cnt) : 0}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="insta_recent_posts">
                                <div className="left">
                                    <p>최근 게시물</p>
                                    <h4>
                                        <span>평균 댓글</span>
                                        {instatTotal?.comment_cnt ? addCommas(instatTotal?.comment_cnt) : 0}
                                    </h4>
                                    <h4>
                                        <span>평균 좋아요</span>
                                        {instatTotal?.like_cnt ? addCommas(instatTotal?.like_cnt) : 0}
                                    </h4>
                                </div>
                                <div className="right">
                                    <div className="row">
                                        {postingList?.slice(3)?.map((item, index) => {
                                            return (
                                                <div className="col-sm-4" key={index}>
                                                    <div className="comn_profl_lyout influ_posting_picss">
                                                        <img src={item?.media_url !== "except X" ? item?.media_url : DummyImg} onError={postingImgError} alt="" />
                                                    </div>
                                                    <div className="influ_posting_like_comt">
                                                        <ul>
                                                            <li>{item?.like_cnt ? formatInstagramKorean(item?.like_cnt) : 0}</li>
                                                            <li>{item?.comment_cnt ? formatInstagramKorean(item?.comment_cnt) : 0}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="follower_info_new mb_10">
                        <div className="left graph_info">
                            <div className="tooltip1">
                                <label>Reels</label>
                                <span className="tooltiptext1 tooltiptext5">최근 30일간의 데이터를 바탕에 의한 분석 입니다.</span>
                            </div>
                        </div>

                        <div className="right">
                            <div className="insta_recent_posts insta_recent_reels">
                                <div className="left">
                                    <p>최근 릴스</p>
                                    <h4>
                                        <span>평균 댓글</span>140
                                    </h4>
                                    <h4>
                                        <span>평균 좋아요</span>900
                                    </h4>
                                </div>
                                <div className="right">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="comn_profl_lyout influ_posting_picss">
                                                <img src={postingList && postingList[2]?.media_url !== "except X" ? postingList[2]?.media_url : DummyImg} alt="" />
                                            </div>
                                            <div className="influ_posting_like_comt">
                                                <ul>
                                                    <li>{postingList ? formatInstagramKorean(postingList[2]?.like_cnt) : 0}</li>
                                                    <li>{postingList ? formatInstagramKorean(postingList[2]?.comment_cnt) : 0}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row insta_recent_reels_graphs">
                                        <div className="col-sm-4">
                                            <div className="comn_profl_lyout influ_posting_picss position-relative">
                                                <h5>Reels</h5>
                                                <ReelsPieGraph />
                                                <div className="graphNine_details">
                                                    <p>
                                                        <span className="dot dot1"></span>
                                                        <span className="headtext">Reels</span> <span className="text">60</span>
                                                    </p>
                                                    <p>
                                                        <span className="dot dot2"></span>
                                                        <span className="headtext">전체</span> <span className="text">200</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="comn_profl_lyout influ_posting_picss reels_graph_text">
                                                <h5>Reels 좋아요 수 </h5>
                                                <div>
                                                    <ReelsBarGraph />
                                                    <p>
                                                        1.5만개 <span>Reels</span>
                                                    </p>
                                                    <p>
                                                        1.5만개 <span>Reels</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="comn_profl_lyout influ_posting_picss reels_graph_text">
                                                <h5>Reels 댓글 수 </h5>
                                                <div>
                                                    <ReelsBarGraph />
                                                    <p>
                                                        1.5만개 <span>Reels</span>
                                                    </p>
                                                    <p>
                                                        1.5만개 <span>Reels</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {getInfo?.current_status == "recruiting" ||
                        (getInfo?.current_status == "recruitDone" && (
                            <div className="col-sm-12 text-center  mt-5">
                                {userInfo?.drafting === "Y" ? (
                                    <Button className="btn_layout un_active" onClick={() => influeSelectClick(userInfo?.drafting)}>
                                        인플루언서 선발 취소
                                    </Button>
                                ) : (
                                    <Button className="btn_layout" onClick={() => influeSelectClick(userInfo?.drafting)}>
                                        인플루언서 선발 하기
                                    </Button>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </Fragment>
    );
}

export default InfluencerProfile_new;
