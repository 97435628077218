import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import { Modal } from "react-bootstrap";

function ProductRecall({ campaignId, influencerId, setChangeEvent, show, handleClose }) {
    const ref = useRef(null);

    const returnCheckEvt = () => {
        AxiosPost("webcampaign/productReturn", {
            campaign_id: campaignId,
            influencer_id: influencerId,
            return_state: "Y",
        }).then((res) => {
            if (res?.data?.result === "success") {
                // AxiosPost("push/individualPushV1", { influencer_id: influencerId, title: "회수 요청", bodyText: "회수 요청" }).catch((error) => {
                //     return true;
                // });
                setChangeEvent(true);
                ref.current.click();
            }
        });
    };

    return (
        <Modal
            className="modal fade user_modal campaign_modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            id="product_recall"
            show={show}
            onHide={handleClose}
        >
            <Modal.Body>
                <div className="modal-content1">
                    <div className="modal-body text-center">
                        <p> 상품 회수를 요청하시겠습니까? </p>

                        <div className="row mt-5">
                            <div className="col-sm-6">
                                <Button className="btn_layout un_active width_100" data-bs-dismiss="modal" ref={ref} onClick={handleClose}>
                                    취소
                                </Button>
                            </div>

                            <div className="col-sm-6">
                                <Button className="btn_layout active width_100" onClick={returnCheckEvt}>
                                    확인
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ProductRecall;
