import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import localStorage from "redux-persist/es/storage";
import { addCommas } from "components/common/common";

const CampaignCostEstimate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [price, setPrice] = useState(0);

    const locationData = location.state.data;

    const campaignChannel = () => {
        let result = "";
        switch (locationData.channelValue) {
            case "instaPosting":
                result = "인스타그램 포스팅";
                break;
            case "instaReels":
                result = "인스타그램 릴스";
                break;
            case "youtube":
                result = "유튜브 비디오";
                break;
            case "tiktok":
                result = "틱톡";
                break;

            default:
                break;
        }
        return result;
    };

    const nextPageBtn = () => {
        navigate("/registercampaign", {
            state: {
                data: locationData,
                price: price,
            },
        });
    };

    useEffect(() => {
        let count = parseInt(locationData.countNum);
        let point = parseInt(locationData.point);
        let caResult = (count - 10) * point;
        let fee = caResult * 0.3;
        // ? 10명 이하는 무조건 30만원을 나오게 했는데 포인트가 있을 경우 포인트 가격이 30만원이 초과되는 상황이 나올 수도 있음.
        let result;
        // 기자단, 상품 10명 이하 무조건 30만원
        if ((count <= 10 && locationData.progressType === "P") || (count <= 10 && locationData.progressType === "E")) {
            result = 300000;
            // 포인트 지급 10명 이하
        } else if (count <= 10 && locationData.progressType === "R") {
            result = 300000 + count * point * 0.3;
            // 기자단, 상품 10명 이상 기본 30만원 + 입력 한 명수 - 10 * 15,000
        } else if (locationData.progressType === "P" || locationData.progressType === "E") {
            result = 300000 + (count - 10) * 15000;
            // 포인트 지급 10명 이상 기본 30만원에 포인트와 0.3 수수료 더해줌
        } else if (locationData.progressType === "R") {
            result = 300000 + caResult + fee;
        }
        setPrice(result);
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="mycampaign campaign_estimate  mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 예상 견적</h2>

                    <div className="findresults mb-5">
                        <p>
                            이 금액은 설정하신 조건으로 캠페인을 진행했을 경우의 예상 견적 확인용입니다. <br />
                            실제 캠페인 진행 금액은 다를 수 있습니다.
                        </p>
                    </div>

                    <div className="campaign_cost">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>프리미언 채널</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>{campaignChannel()}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <p>캠페인 진행 방식</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>{locationData?.progressType === "P" ? "상품으로만 진행" : locationData?.progressType === "R" ? "더프리미언 추천" : "기자단"}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <p>모집인원</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>{locationData?.countNum}명</p>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-sm-6">
                                    <p>예상 총 도달 수 (KPI)</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>33,402</p>
                                </div>
                            </div> */}

                            <div className="row fw-bolder">
                                <div className="col-sm-6">
                                    <p>총 예상 금액</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>{addCommas(price)}원</p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-sm-12 text-center">
                                    <Button
                                        className="cost_btn btn_layout"
                                        // to="/registercampaign"
                                        onClick={nextPageBtn}
                                    >
                                        캠페인 요청서 작성하기
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CampaignCostEstimate;
