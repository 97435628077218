import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";

import { useForm } from "react-hook-form";
import { AgeDataList } from "components/db/AgeDataList";
import { regionDataList } from "components/db/DataArrayList";
import { locale } from "components/common/common";
import { handleMultiImageChange } from "components/untils/UploadImg";
import { GetMinDate, todayDate } from "components/untils/GetMinDate";

function VisitReviewBasicInfo({ setActiveTab, jsonData, setJsonData }) {
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [postingStart, setPostingStart] = useState(null);
    const [postingEnd, setPostingEnd] = useState(null);
    const [imageState, setImageState] = useState([]);
    const [reqImage, setReqImage] = useState([]);
    const [firstCheckbox, setFirstCheckbox] = useState(false);
    const [otherCheckboxes, setOtherCheckboxes] = useState([]);

    const handleDateChange = (event, picker) => {
        setDateStart(picker.startDate);
        setDateEnd(picker.endDate);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === "전국") {
            setFirstCheckbox(checked);
            setOtherCheckboxes(["전국"]);

            if (!checked) {
                setOtherCheckboxes([]);
            }
        } else {
            if (!firstCheckbox) {
                setOtherCheckboxes((prevCheckboxes) => {
                    if (checked) {
                        return [...prevCheckboxes, name];
                    } else {
                        return prevCheckboxes.filter((checkbox) => checkbox !== name);
                    }
                });
            }
        }
    };

    const handlePostingDateChange = (event, picker) => {
        setPostingStart(picker.startDate);
        setPostingEnd(picker.endDate);
    };
    const { register, handleSubmit, formState } = useForm({ mode: "onChange" });

    const handleForm = (e) => {
        const address_details = document.querySelector(".address_details");
        if (e.target.id === "retrieval1") {
            address_details.style.display = "flex";
        }
        if (e.target.id === "free1") {
            // console.warn("hi");
            address_details.style.display = "none";
        }
    };

    const handleImageChange = (e) => {
        handleMultiImageChange(e, setImageState, setReqImage);
    };

    const onSubmit = (data) => {
        let gender = data?.gender?.length === 2 ? "A" : data?.gender[0] === "W" ? "W" : "M";
        // let age = data?.agecondition?.length === 7 ? "all" : data?.agecondition;
        // if (!reqImage?.length) {
        //     alert("이미지를 업로드해주세요.");
        // } else {
        let setData = {
            product_name: data.productName,
            campaign_postingperiod: [
                {
                    start_date: dateStart?.format("YYYY-MM-DD HH:mm:ss"),
                    end_date: dateEnd?.format("YYYY-MM-DD HH:mm:ss"),
                    period_type: "인플루언서 모집기간",
                    date_type: "I",
                },
                {
                    start_date: postingStart?.format("YYYY-MM-DD HH:mm:ss"),
                    end_date: postingEnd?.format("YYYY-MM-DD HH:mm:ss"),
                    period_type: "캠페인 포스팅 기간",
                    date_type: "P",
                },
            ],
            sponsor_method: {
                sponsor_method: data.sponsor_method,
                discount: data.discount,
            },
            gender_condition: gender,
            agecondition: data?.agecondition,
            campaign_regioncondition: otherCheckboxes,
            campaign_product_pictures: reqImage,
            campaign_product_preview: imageState,
        };

        let tempJson = jsonData;
        for (var key in setData) {
            tempJson[key] = setData[key];
        }
        setJsonData(tempJson);
        setActiveTab("moreinfo");
        // }
    };

    const onError = (data) => {
        console.log("onError --- ", data);
    };

    const DateComponent = ({ minDate }) => {
        return (
            <DateRangePicker
                onApply={handlePostingDateChange}
                initialSettings={{
                    locale: locale,
                    minDate: minDate,
                }}
            >
                <div className="clander_input">
                    <input type="text" className="form-control" value={postingStart ? postingStart?.format("YYYY-MM-DD") : ""} placeholder="포스팅 시작일" readOnly />
                    <input type="text" className="form-control " value={postingEnd ? postingEnd?.format("YYYY-MM-DD") : ""} placeholder="포스팅 마감일" readOnly />
                </div>
            </DateRangePicker>
        );
    };

    return (
        <Fragment>
            <div className="basic_register_campaign  common_review">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">01</span> 홍보하실 상품명 / 브랜드명 / 서비스명을 입력해 주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input
                                        className="form-control"
                                        {...register("productName", {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">02</span> 캠페인 대표 이미지를 업로드해주세요<span className="required_field">*</span>{" "}
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="col-sm-12 ">
                                        <input
                                            type="file"
                                            id="addFile"
                                            name="descriptionFile"
                                            accept="image/*"
                                            style={{ display: "inline-block", padding: "20px" }}
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="addFile" className="addFile w_auto ">
                                            파일 첨부
                                        </label>
                                    </div>
                                    <div className="form-text ">*이미지는 10MB까지 업로드 가능해요</div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">03</span> 모집 기간을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <DateRangePicker
                                        onApply={handleDateChange}
                                        initialSettings={{
                                            locale: locale,
                                            minDate: todayDate(),
                                        }}
                                    >
                                        <div className="clander_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={dateStart ? dateStart?.format("YYYY-MM-DD") : ""}
                                                placeholder="모집 시작일"
                                                readOnly
                                            />
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={dateEnd ? dateEnd?.format("YYYY-MM-DD") : ""}
                                                placeholder="모집 마감일"
                                                readOnly
                                            />
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">04</span> 캠페인 포스팅 기간을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <DateComponent minDate={GetMinDate(dateEnd, 1)} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">05</span>제품 협찬 방법을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            id="free1"
                                            type="radio"
                                            name="shooting"
                                            value="free"
                                            {...register("sponsor_method", {
                                                onChange: (e) => handleForm(e),
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="free1">
                                            무료제공
                                        </label>
                                    </div>

                                    <div className="form-check width_100  mb-3">
                                        <div className="row">
                                            <div className="col-sm-2" style={{ padding: "15px" }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="retrieval1"
                                                    name="shooting"
                                                    value="retrieval"
                                                    {...register("sponsor_method", {
                                                        onChange: (e) => handleForm(e),
                                                    })}
                                                />
                                                <label className="form-check-label" htmlFor="retrieval1">
                                                    할인판매{" "}
                                                </label>
                                            </div>

                                            <div className="col-sm-9">
                                                <div className="row address_details" style={{ display: "none" }}>
                                                    <div className="col-sm-4">
                                                        <input className="form-control" {...register("discount")} />
                                                    </div>
                                                    <div className="col-sm-2 p-0">
                                                        <input className="form-control" placeholder="원" readOnly  style={{border:"none", padding:"15px 0"}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7 gender_age">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">06</span> 프리미언(인플루언서) 조건을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">성별</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    <div className="form-check form-check-inline  mb-3">
                                        <input className="form-check-input" type="checkbox" name="gender" value="M" id="male" {...register("gender")} />
                                        <label className="form-check-label" htmlFor="male">
                                            {" "}
                                            남성{" "}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline  mb-3">
                                        <input className="form-check-input" type="checkbox" name="gender" value="W" id="female" {...register("gender")} />
                                        <label className="form-check-label" htmlFor="female">
                                            {" "}
                                            여성{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">연령대</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    {AgeDataList?.map((item) => {
                                        return (
                                            <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                <input className="form-check-input" type="checkbox" value={item.value} id={item.value} {...register("agecondition")} />
                                                <label className="form-check-label" htmlFor={item.value}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">지역</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    <div className="form-check form-check-inline  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="전국"
                                            value="전국"
                                            id="allRegion"
                                            onChange={(e) => handleCheckboxChange(e)}
                                            checked={firstCheckbox}
                                        />
                                        <label className="form-check-label" htmlFor="allRegion">
                                            전국
                                        </label>
                                    </div>
                                    {regionDataList?.map((item) => {
                                        if (item.value !== "전국") {
                                            const isChecked = otherCheckboxes.includes(item.value);
                                            return (
                                                <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name={item.value}
                                                        value={item.value}
                                                        id={item.value}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        disabled={firstCheckbox && item.value !== "전국"}
                                                        checked={isChecked}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.value}>
                                                        {item.value}
                                                    </label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12 text-center">
                                <Button className="btn_layout" type="submit">
                                    상세정보 입력하기
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default VisitReviewBasicInfo;
