import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useSelector } from "react-redux";
import AxiosPost from "components/untils/AxiosPost";
import moment from "moment";
import { useLocation } from "react-router-dom";

import OngoingCampaign from "components/MyCampaign/OngoingCampaign";
import NoOngoingCampaign from "components/MyCampaign/NoOngoingCampaign";
import NoCompletedCampaign from "components/MyCampaign/NoCompletedCampaign";
// import CompletedCampaign from "components/MyCampaign/CompletedCampaign";
import CompletedCampaign from "components/MyCampaign/CompletedCampaign_new";

const MyCampaign = () => {
    const location = useLocation();
    const campaignType = location?.state?.type;
    const loginSeq = useSelector((state) => state.auth.seq);
    const [myCampaignList, setMyCampaignList] = useState([]);
    const [myEndCampaignList, setMyEndCampaignList] = useState([]);
    const [activeKey, setActiveKey] = useState(campaignType === "complete" ? "account1" : "account");
    const [selectValue, setSelectValue] = useState("");

    const setToday = () => {
        return moment().format("YYYY-MM-DD");
    };

    const handleTabSelect = (key) => {
        setActiveKey(key);
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectValue(value);
    };

    useEffect(() => {
        let today = setToday();
        AxiosPost("webcampaign/myCampaigns", { client_id: loginSeq, today: today, currentStatus: selectValue })
            .then((res) => {
                // console.log("진행중 캠페인 --- ", res.data);
                if (res?.data?.result === "success") {
                    let setData = res?.data?.data?.rows;
                    setMyCampaignList(setData);
                    // let dataCount = res?.data?.data?.count;
                    // let processData = setData;
                    // // 캠페인이 하나도 없을때만 데모 데이터가 보이게
                    // if (dataCount > 1 && loginSeq !== 14) {
                    //     processData = setData?.filter((item) => item.active === "Y");
                    //     setMyCampaignList(processData);
                    // } else {
                    //     setMyCampaignList(setData);
                    // }
                } else {
                    setMyCampaignList([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [selectValue]);

    useEffect(() => {
        let today = setToday();
        AxiosPost("webcampaign/doneCampaign", { client_id: loginSeq, today: today, currentStatus: selectValue })
            .then((res) => {
                // console.log("완료한 캠페인 --- ", res.data.data.rows?.length);
                if (res?.data?.result === "success") {
                    setMyEndCampaignList(res?.data?.data?.rows);
                } else {
                    setMyEndCampaignList([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [selectValue]);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="mycampaign faq_panel mtb190 mycampaign_new">
                <div className="container">
                    <h2 className="inner_heading">마이 캠페인</h2>
                    <div className="col-sm-2 float-end display_none">
                        <select className="form-select" aria-label="캠페인 진행상태" value={selectValue} onChange={handleSelectChange}>
                            <option value="">모든 캠페인</option>
                            {/* <option value="">임시저장</option> */}
                            {/* <option value="">캠페인 취소</option> */}
                            <option value="recruiting">모집중</option>
                            <option value="recruitDone">모집완료</option>
                            <option value="shipping">배송중</option>
                            <option value="posting">포스팅 현황</option>
                        </select>
                    </div>

                    <Tabs defaultActiveKey={activeKey} onSelect={handleTabSelect} id="fill-tab-example" className="mb-1" fill>
                        <Tab eventKey="account" title="진행중 캠페인">
                            {myCampaignList?.length ? <OngoingCampaign myCampaignList={myCampaignList} setMyCampaignList={setMyCampaignList} /> : <NoOngoingCampaign />}
                            <br />
                        </Tab>

                        <Tab eventKey="account1" title="완료한 캠페인">
                            {myEndCampaignList?.length ? (
                                <CompletedCampaign myEndCampaignList={myEndCampaignList} setMyEndCampaignList={setMyEndCampaignList} />
                            ) : (
                                <NoCompletedCampaign />
                            )}

                            <br />
                            <br />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
};

export default MyCampaign;
