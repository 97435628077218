import React, { useState } from "react";
import { Fragment } from "react";
import Logo from "assets/images/footer_logo.png";
import Instagram from "assets/images/insta_icon.png";
import Facebook from "assets/images/fb_icon.png";
import Naver from "assets/images/naver_icon.png";
import Youtube from "assets/images/youtube_icon.png";
import KakaoTalk from "assets/images/kakao_icon.png";
import { Link } from "react-router-dom";
import ButtonMailto from "components/ButtonMailto/ButtonMailto";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Footer() {
    const [text, setText] = useState("support@thepreian.com");
    const [isCopied, setIsCopied] = useState(false);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Fragment>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 footer_logo">
                            <Link to="/" onClick={scrollToTop}>
                                {" "}
                                <img className="" src={Logo} alt="더프리미언" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 box_left">
                            <p>
                                <span>
                                    {" "}
                                    서울특별시 강남구 테헤란로87길 36, 15층 (우)06164 (삼성동, 도심공항타워)
                                    <br />
                                    ㈜이든앤앨리스마케팅
                                    <br />
                                    대표자: 홍인표, 이정훈
                                </span>
                                <br /> 사업자등록번호 114-81-61606
                                <br /> ©ETHAN&ALICE MARKETING. ALL RIGHTS RESERVED 2023.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h5>Contact</h5>
                            <p className="position_relative">
                                {isCopied ? (
                                    <p className="success-msg">
                                        이메일 주소가 복사되었습니다. ​<br />
                                        붙여넣기 후 문의 이메일을 보내주세요.
                                    </p>
                                ) : null}

                                <CopyToClipboard
                                    text={text}
                                    onCopy={() => {
                                        setIsCopied(true);
                                        setTimeout(() => {
                                            setIsCopied(false);
                                        }, 2000);
                                    }}
                                >
                                    <Link className="mail_btn">support@thepremian.com</Link>
                                </CopyToClipboard>
                            </p>
                            <p>전화: 02.525.5711</p>
                            <ul className="term_policy">
                                <li>
                                    <Link to="/termofuse" onClick={scrollToTop}>
                                        이용약관
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/privacypolicy" onClick={scrollToTop}>
                                        개인정보취급방침
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 box_right">
                            <ul className="social_links">
                                <li>
                                    <Link to="https://www.instagram.com/thepremian/" target="_blank">
                                        <img src={Instagram} alt="Instagram" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.facebook.com/people/thePremian/100091917731312/" target="_blank">
                                        <img src={Facebook} alt="Facebook" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://blog.naver.com/thepremian" target="_blank">
                                        <img src={Naver} alt="Naver" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://pf.kakao.com/_VYlAG" target="_blank">
                                        <img src={KakaoTalk} alt="KakaoTalk Cahnnel" />
                                    </Link>
                                </li>
                                {/*  <li>
                                    <Link to="https://www.youtube.com/channel/UCfiH_d5rpVW069ijm0__g0A" target="_blank"><img src={Youtube} alt="Youtube" /></Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;
