import React, { Fragment, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import DummyImage from "../../assets/images/dummy_image.png";
import BestLikes from "../../assets/images/best_likes.png";
import BestComments from "../../assets/images/best_comments.png";
import { selectCampaignType } from "components/common/SelectData";
import { addCommas, formatPercent, formatInstagram, formatInstagramKorean } from "components/common/common";
import DummyPostImg from "../../assets/images/img_dummy_pc_440.jpg";
import XageGraph from "components/Graph/XageGraph";
import YregionGraph from "components/Graph/YregionGraph";
import RoundGenderGraph from "components/Graph/RoundGenderGraph";
import moment from "moment";
import LineChart from "components/untils/LineChart";
import AxiosPost from "components/untils/AxiosPost";
import { truncateString } from "components/untils/utils";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function CompletedCampaign({ myEndCampaignList, setMyEndCampaignList }) {
    // console.log("myEndCampaignList --- ", myEndCampaignList[0]?.campaign_id);
    const navigate = useNavigate();
    const [isActive, setActive] = useState("false");
    const [sendKey, setSendKey] = useState(myEndCampaignList[0]?.campaign_id);
    const [postList, setPostList] = useState([]);
    const [dateDatas, setDateDatas] = useState([]);
    const [ageDatas, setAgeDatas] = useState([]);
    const [regionDatas, setRegionDatas] = useState([]);
    const [genderDatas, setGenderDatas] = useState([]);
    const [dataList, setDataList] = useState(null);
    const [lastData, setLastData] = useState(null);

    const handleImageError = (event) => {
        // 에러가 발생했을 때 호출되는 함수
        event.target.src = DummyPostImg; // 대체 이미지로 교체
    };

    const handleToggle = () => {
        setActive(!isActive);
    };

    // 캠페인 디테일 이동
    const clickDetailPage = (item) => {
        navigate(`/campaignDetail/${item}`, {
            state: {
                campaignId: item,
            },
        });
    };

    // 팔로워 데이터 가공
    const followerGraphData = [
        {
            id: "팔로워",
            color: "hsl(82, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.followers_count,
                      })),
        },
    ];

    // 도달율 데이터 가공
    const reachGraphData = [
        {
            id: "도달",
            color: "hsl(82, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.reach_cnt,
                      })),
        },
    ];

    let erResult;
    function erResultEvt(item) {
        let result = (((parseInt(item?.like_cnt) + parseInt(item?.comment_cnt)) / parseInt(item?.followers_count)) * 100).toFixed(2);
        if (result === "Infinity") {
            erResult = 0.2;
        } else {
            erResult = result;
        }
        return erResult;
    }
    // 반응률 데이터 가공
    const erGraphData = [
        {
            id: "반응률(ER)",
            color: "hsl(202, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: erResultEvt(item),
                      })),
        },
    ];

    // 좋아요 데이터 가공
    const likeGraphData = [
        {
            id: "좋아요",
            color: "hsl(240, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.like_cnt,
                      })),
        },
    ];
    // 댓글 데이터 가공
    const commentGraphData = [
        {
            id: "댓글",
            color: "hsl(349, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.comment_cnt,
                      })),
        },
    ];

    // 데이터 가공
    const graphData = [
        {
            id: "좋아요",
            color: "hsl(240, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.like_cnt,
                      })),
        },
        {
            id: "댓글",
            color: "hsl(349, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.comment_cnt,
                      })),
        },
    ];

    const plusValueData = dateDatas?.map((item) => ({
        create_at: item?.create_at,
        followers_count: item?.followers_count,
        comment_cnt: item?.comment_cnt,
        like_cnt: item?.like_cnt,
        reach_cnt: item?.reach_cnt,
        ER: (((parseInt(item?.like_cnt) + parseInt(item?.comment_cnt)) / parseInt(item?.followers_count)) * 100).toFixed(2),
    }));

    const checkErrorNumber = (number) => {
        if (number === Infinity) {
            return 0;
        } else if (isNaN(number)) {
            return 0;
        } else {
            return number;
        }
    };

    // 나이 배열 가공
    function ageChangeNumber(item) {
        let array = item?.split(", ").map((str) => str.replace(/[^0-9]/g, ""));
        return array?.join(", ");
    }

    // 나이 그래프 데이터 가공
    function countOccurrences(arr) {
        return arr.reduce((acc, currentValue) => {
            acc[currentValue] = (acc[currentValue] || 0) + 1;
            return acc;
        }, {});
    }

    // 해시태그 추출
    function hastagResult(content) {
        const hashtags = content.match(/#[a-z0-9_가-힣]+/gm);

        // 최대 3개의 해시태그만 추출
        const limitedHashtags = hashtags ? hashtags.slice(0, 3) : [];

        // 해시태그를 하나의 텍스트로 합치고 15자 이내로 자르기
        let combinedText = limitedHashtags.join(" ");
        if (combinedText.length > 18) {
            combinedText = combinedText.substring(0, 18) + "...";
        }
        return combinedText;
    }

    useEffect(() => {
        AxiosPost("webcampaign/instarPostList", { campaign_id: sendKey })
            .then((res) => {
                setDateDatas(res?.data?.data?.dateData);
                // console.log("11", res?.data?.data);
                let datas = res?.data?.data?.dateData;
                setLastData(datas[datas?.length - 1]);
                setPostList(res?.data?.data?.rows);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [sendKey]);

    useEffect(() => {
        AxiosPost("webcampaign/getCampianPremianInfo", { campaign_id: sendKey })
            .then((res) => {
                // console.log("11", res?.data?.data);
                let agedb = res?.data?.data?.age_group?.split(",");
                let genderdb = res?.data?.data?.gender?.split(",");
                let regiondb = res?.data?.data?.regions?.split(",");
                setDataList(res?.data?.data);
                setAgeDatas(countOccurrences(agedb));
                setRegionDatas(countOccurrences(regiondb));
                setGenderDatas(countOccurrences(genderdb));
            })
            .catch((error) => {
                console.error(error);
            });
    }, [sendKey]);

    return (
        <Fragment>
            <div className="ongoing_campaign completed_campaign completed_campaign_new">
                <Accordion defaultActiveKey={0}>
                    {myEndCampaignList &&
                        myEndCampaignList?.map((item, index) => {
                            return (
                                <Accordion.Item
                                    eventKey={myEndCampaignList[0]?.campaign_id === item?.campaign_id ? 0 : index + 1}
                                    onClick={() => {
                                        setSendKey(item?.campaign_id);
                                    }}
                                    key={item?.campaign_id}
                                >
                                    <Accordion.Header>
                                        <div className="col-lg-11 col-md-11 col-sm-11 table-responsive">
                                            <table>
                                                <thead>
                                                    <tr
                                                        onClick={() => {
                                                            clickDetailPage(item?.campaign_id);
                                                        }}
                                                    >
                                                        <th>캠페인명</th>
                                                        <th>캠페인 유형</th>
                                                        <th>캠페인 진행기간</th>
                                                        <th>참여자수 / 지원자수 / 모집자수</th>
                                                        <th>집행 광고비 (원)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="fixed_height">{item?.campaign_name}</div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">{selectCampaignType(item?.campaign_type)}</div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">
                                                                {moment(item?.posting_start_date).format("YY-MM-DD")} - {moment(item?.posting_end_date).format("YY-MM-DD")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">
                                                                {item?.posting_count ? item?.posting_count : 0} / {item?.influencer_count ? item?.influencer_count : 0} /{" "}
                                                                {item?.total_count ? item?.total_count : 0}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">{item?.campaign_price ? addCommas(item?.campaign_price) : 0}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-2 left_headings_new">
                                                <label>캠페인 성과</label>
                                            </div>
                                            <div className="col-sm-10 padding_0">
                                                <div className="countdown">
                                                    <div className="row">
                                                        <div className="col-sm-2 ps-0 pe-0">
                                                            <div className="countdown_info countdown_heading">
                                                                <p>
                                                                    <span>총 참여자 수</span>
                                                                    {item?.posting_count}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>팔로워</span>
                                                                    {lastData?.followers_count ? addCommas(lastData?.followers_count) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>도달</span>
                                                                    {lastData?.reach_cnt ? addCommas(lastData?.reach_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>좋아요</span>
                                                                    {lastData?.like_cnt ? addCommas(lastData?.like_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>댓글</span>
                                                                    {lastData?.comment_cnt ? addCommas(lastData?.comment_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>반응률(ER)</span>
                                                                    {parseInt(lastData?.followers_count)
                                                                        ? formatPercent(
                                                                              ((parseInt(lastData?.like_cnt) + parseInt(lastData?.comment_cnt)) /
                                                                                  parseInt(lastData?.followers_count)) *
                                                                                  100
                                                                          )
                                                                        : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 d-none">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>CPV</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2 display_none">
                                                <img src={item?.image_data} alt="Dummy Image" />
                                            </div>
                                        </div>
                                        <div className="row graph_info" style={{ marginTop: "50px" }}>
                                            <div className="tooltip1">
                                                <label>캠페인 통계 데이터</label>
                                                <span className="tooltiptext1">통계 데이터는 포스팅 업로드 시점 부터 누적되어 보여집니다.</span>
                                            </div>

                                            <div className="graph">
                                                {graphData[0]?.data?.length > 0 && graphData[1]?.data?.length > 0 && (
                                                    <Tabs defaultActiveKey="follower" id="uncontrolled-tab-example" className="mb-1" fill>
                                                        <Tab eventKey="follower" title="팔로워">
                                                            <LineChart datas={followerGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="reach" title="도달">
                                                            <LineChart datas={reachGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="like" title="좋아요">
                                                            <LineChart datas={likeGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="comment" title="댓글 ">
                                                            <LineChart datas={commentGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="er" title="반응률(ER)">
                                                            <LineChart datas={erGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                    </Tabs>
                                                )}

                                                <div className="graph_details">
                                                    <div className="row graph_details_new">
                                                        <div className="col-sm-7">
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <div className="graph_details_title">
                                                                        <div className="tooltip1">
                                                                            {" "}
                                                                            <label>제품/브랜드 도달효과</label>
                                                                            <span className="tooltiptext1 tooltiptext2">캠페인 포스팅에 보여지는 고유 팔로워 수입니다.</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="graph_details_body">
                                                                        최소 <span>{lastData?.reach_cnt ? addCommas(lastData?.reach_cnt) : 0}</span>의<br /> 팔로워에게 캠페인 도달
                                                                    </p>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <div className="graph_details_title">
                                                                        <div className="tooltip1">
                                                                            {" "}
                                                                            <label>노출효과</label>
                                                                            <span className="tooltiptext1 tooltiptext2">캠페인 포스팅에 보여진 총 횟수입니다.</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="graph_details_body">
                                                                        해당 캠페인의 포스팅들이 <br /> 총{" "}
                                                                        <span>{item?.followers_cnt ? addCommas(item?.followers_cnt + item?.comment_cnt + item?.like_cnt) : 0}</span>
                                                                        번 노출
                                                                    </p>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <div className="graph_details_title">
                                                                        <div className="tooltip1">
                                                                            {" "}
                                                                            <label>반응효과</label>
                                                                            <span className="tooltiptext1 tooltiptext2">캠페인 포스팅에 반응한 좋아요/댓글의 계정 수입니다.</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="graph_details_body">
                                                                        총 <span>{item ? addCommas(item?.comment_cnt + item?.like_cnt) : 0}</span>번의 <br />
                                                                        포스팅에 대한 반응
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-5">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="graph_details_title">
                                                                        <div className="tooltip1">
                                                                            {" "}
                                                                            <label>반응 건당 비용(CPE)</label>
                                                                            <span className="tooltiptext1 tooltiptext3">
                                                                                캠페인 집행금액을 좋아요/댓글에 반응한 수로 나눈 비용입니다.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="graph_details_body graph_details_body_cp">
                                                                        <span>
                                                                            {addCommas(
                                                                                checkErrorNumber(
                                                                                    parseInt(item?.campaign_price) / (item?.like_cnt + item?.comment_cnt * 2)
                                                                                )?.toFixed(1)
                                                                            )}
                                                                            원
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="graph_details_title">
                                                                        <div className="tooltip1">
                                                                            {" "}
                                                                            <label>노출건당 비용(CPR)</label>
                                                                            <span className="tooltiptext1 tooltiptext4">
                                                                                캠페인 집행금액을 총 포스팅이 노출된 수로 나눈 비용의 값입니다.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="graph_details_body graph_details_body_cp">
                                                                        <span>{checkErrorNumber(item?.campaign_price / item?.followers_cnt)?.toFixed(1)}원</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="follower_info_new">
                                            <div className="left">
                                                <label>도달 팔로워 정보</label>
                                            </div>
                                            <div className="right">
                                                <div className="follower_info_graphs">
                                                    <div>
                                                        <label>연령별 비율</label>
                                                        {ageDatas && <XageGraph dbData={ageDatas} />}
                                                    </div>
                                                </div>
                                                <div className="follower_info_graphs">
                                                    <div>
                                                        <label>전체 성별 비율</label>
                                                        {genderDatas && <RoundGenderGraph dbData={genderDatas} />}
                                                    </div>
                                                </div>
                                                <div className="follower_info_graphs">
                                                    <div>
                                                        <label>지역</label>
                                                        {regionDatas && <YregionGraph dbData={regionDatas} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="follower_info_new premian_comparison">
                                            <div className="left">
                                                <label>도달 팔로워 정보</label>
                                            </div>
                                            <div className="right">
                                                <div className="w-100 d-block float-start">
                                                    <div className="premian_comparison_header">
                                                        <p>
                                                            <span>요청 프리미언 조건</span>
                                                        </p>
                                                        <p>
                                                            <span>참여 프리미언 분석</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="premian_comparison_detail">
                                                    <div className="premian_comparison_detail_lft">
                                                        <ul>
                                                            <li>모집자 수</li>
                                                            <li>카테고리</li>
                                                            <li>연령대</li>
                                                            <li>성별</li>
                                                            <li>카테고리</li>
                                                        </ul>
                                                    </div>

                                                    <div className="premian_comparison_detail_rht">
                                                        <div>
                                                            <ul>
                                                                <li>{item?.total_count ? item?.total_count : 0}명</li>
                                                                <li>{item?.categorys}</li>
                                                                <li>{ageChangeNumber(item?.ages)}</li>
                                                                <li>{item?.gender_condition === "W" ? "여성" : item?.gender_condition === "M" ? "남성" : "남성, 여성"}</li>
                                                                <li>{item?.regions}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="text_blue">
                                                            <ul>
                                                                <li>{item?.posting_count ? item?.posting_count : 0}명 </li>
                                                                <li>{dataList?.category_datas}</li>
                                                                <li>{dataList?.age_datas}</li>
                                                                <li>{dataList?.gender_datas}</li>
                                                                <li>{dataList?.region_datas}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`padding_0 row registered_post follower_info_new ${isActive ? "expand_list" : ""}`}>
                                            <div className="left">
                                                <label>포스팅 현황</label>
                                            </div>
                                            <div className="right">
                                                {postList?.map((item, index) => {
                                                    return (
                                                        <div className="insta_posts_new_box" key={item?.posturl_id}>
                                                            <div className="compare_lks_cmts">
                                                                {index == 0 ? <img src={BestLikes} /> : index == 1 ? <img src={BestComments} /> : null}
                                                            </div>
                                                            <div className="insta_posts_new">
                                                                <div className="left_img">
                                                                    <a href={item?.post_url} target="_blank">
                                                                        <img
                                                                            src={item?.media_url ? item?.media_url : DummyPostImg}
                                                                            onError={handleImageError}
                                                                            // style={{ width: "210px", height: "210px" }}
                                                                        />
                                                                    </a>
                                                                    <div className="comt_likes">
                                                                        <p className="likes">{item?.like_cnt === "0" ? "여러명" : addCommas(item?.like_cnt)}</p>
                                                                        <p className="coments">{addCommas(item?.comment_cnt)}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="right_text">
                                                                    <label>포스트 캡션 </label>
                                                                    <p></p>
                                                                    <p>{hastagResult(item?.contents)} </p>
                                                                    <ul>
                                                                        <li>
                                                                            팔로워 수
                                                                            <span>{item?.followers_count === 0 ? "여러명" : formatInstagramKorean(item?.followers_count)}</span>
                                                                        </li>
                                                                        <li>
                                                                            평가점수<span>{item?.confirm_avg == 0 ? item?.result : item?.confirm_avg}</span>
                                                                        </li>
                                                                        {item?.media_carousel_album_reach ? (
                                                                            <li>
                                                                                도달<span>{addCommas(item?.media_carousel_album_reach)}</span>
                                                                            </li>
                                                                        ) : null}

                                                                        <li>
                                                                            매칭률<span>{item?.matchPercenTage}%</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="padding_0 row follower_info_new">
                                            <div className="right">
                                                <p style={{ textAlign: "right" }}>'여러명'및 N/A은 프리미언이 기능을 숨긴 경우 입니다.</p>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 text-center">
                                            <Button className="btn_layout" onClick={handleToggle}>
                                                포스팅 더 보기
                                            </Button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                </Accordion>
            </div>
        </Fragment>
    );
}

export default CompletedCampaign;
