// import MainPage from "./MainPage";
import JoinPage from "./User/JoinPage";
import FindId from "./User/FindId";
import FindPwd from "./User/FindPwd";
import UserInfo from "./User/UserInfo";
import TabPage from "./User/TabPage";
import TabFindResult from "./User/TabFindResult";
import SettlementDetails from "./SettlementDetails/SettlementDetails";
import JoinPageResult from "./User/JoinPageResult";
import FindPwdResult from "./User/FindPwdResult";
import ContactUs from "./ContactUs/ContactUs";
import FAQ from "./FAQ/FAQ";
import CampaignEstimate from "./Campaign/CampaignEstimate";
import CampaignCostEstimate from "./Campaign/CampaignCostEstimate";
import CampaignPaymentDone from "./Campaign/CampaignPaymentDone";
import CampaignQuote from "./Campaign/CampaignQuote";
import MyCampaign from "./MyCampaign/MyCampaign";
import RegisterCampaign from "./Campaign/RegisterCampaign";
import CampaignDetail from "./MyCampaign/CampaignDetail";
import CampaignManagement from "./MyCampaign/CampaignManagement";
import InfluencerProfile from "./MyCampaign/InfluncerProfile";
import InfluencerProfile_new from "./MyCampaign/InfluncerProfile_new";
import TermOfUse from "./Disclosure/TermOfUse";
import PrivacyPolicy from "./Disclosure/PrivacyPolicy";
import LandingPage from "./LandingPage/LandingPage";
import TemporayCampaign from "./TemporayCampaign/TemporayCampaign";

export {
    // MainPage,
    JoinPage,
    FindId,
    FindPwd,
    UserInfo,
    TabPage,
    TabFindResult,
    SettlementDetails,
    JoinPageResult,
    FindPwdResult,
    ContactUs,
    FAQ,
    CampaignEstimate,
    CampaignCostEstimate,
    CampaignPaymentDone,
    CampaignQuote,
    MyCampaign,
    RegisterCampaign,
    CampaignDetail,
    CampaignManagement,
    InfluencerProfile,
    InfluencerProfile_new,
    TermOfUse,
    PrivacyPolicy,
    LandingPage,
    TemporayCampaign,
};
