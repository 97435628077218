import moment from "moment";
import React from "react";
export function isValidBizNum(bizNum) {
    // 사업자등록번호는 '-'을 제외한 10자리 숫자이어야 함
    if (!/^\d{10}$/.test(bizNum)) return false;

    const checkArr = [1, 3, 7, 1, 3, 7, 1, 3, 5]; // 인증키(고정)
    let sum = 0;

    // 인증키와 자릿수같은 숫자끼리 곱한 뒤, 계속 더해준다.
    for (let i = 0; i < checkArr.length; i++) {
        sum += parseInt(bizNum.charAt(i), 10) * checkArr[i];
    }

    // 9번째 자리의 숫자에 0.5를 곱한 뒤, 다시 더해준다.
    sum += Math.floor((parseInt(bizNum.charAt(8), 10) * 5) / 10);

    // '10 - (지금까지 더한 수의 나머지) == 사업자번호의 마지막 자릿수'가 같다면 유효한 사업자 등록번호
    return (10 - (sum % 10)) % 10 === parseInt(bizNum.charAt(9), 10);
}

// export function isValidBizNum(bizNum) {
//     console.log("bizNum", bizNum);
//     if (bizNum.length !== 10) {
//         return false; // 사업자등록번호는 10자리여야 함
//     }
//     var sum = 0;
//     var checkArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
//     for (var i = 0; i < checkArr.length; i++) {
//         sum += parseInt(bizNum.charAt(i), 10) * checkArr[i];
//     }
//     sum += Math.floor((parseInt(bizNum.charAt(8), 10) * 5) / 10);
//     sum += (parseInt(bizNum.charAt(8), 10) * 5) % 10;
//     sum += parseInt(bizNum.charAt(9), 10);
//     return sum % 10 === 0;
// }

// 사업자 등록번호 30일이 지났는지
export function isOver30Days(dateStringFromDB) {
    const now = moment();
    const dateFromDB = moment(dateStringFromDB);
    const diffInDays = now.diff(dateFromDB, "days");
    return diffInDays >= 30;
}

// 날짜 라이브러리 한글
export const locale = {
    format: "YYYY/MM/DD",
    separator: " - ",
    applyLabel: "적용",
    cancelLabel: "취소",
    fromLabel: "From",
    toLabel: "To",
    customRangeLabel: "Custom",
    weekLabel: "W",
    daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"],
    monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
    firstDay: 1,
};

export function addCommas(number) {
    // 숫자를 문자열로 변환
    const numStr = String(number);

    // 소수점 위치를 찾기 위해 숫자를 점으로 나눔
    const parts = numStr.split(".");

    // 정수 부분에 쉼표 추가
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // 쉼표가 추가된 정수 부분과 소수 부분을 합쳐서 반환
    return parts.join(".");
}
export function formatInstagramNumber(number) {
    if (number < 1000) {
        // 1,000 미만의 경우 그대로 반환
        return String(number);
    } else if (number < 1000000) {
        // 1,000 이상 1,000,000 미만인 경우 "K"를 붙여 반환
        return (number / 1000).toFixed(1) + "K";
    } else {
        // 1,000,000 이상인 경우 "M"을 붙여 반환
        return (number / 1000000).toFixed(1) + "M";
    }
}

export function formatInstagram(number) {
    number = parseFloat(number?.toString()?.replace(/,/g, "")) || 0;
    if (number < 1000) {
        // 1,000 미만의 경우 그대로 반환
        return String(Math.round(number));
    } else if (number < 1000000) {
        // 1,000 이상 1,000,000 미만인 경우 "K"를 붙여 반환
        const formatted = (number / 1000)?.toFixed(1);
        return formatted.endsWith(".0") ? formatted.slice(0, -2) + "K" : formatted + "K";
    } else {
        // 1,000,000 이상인 경우 "M"을 붙여 반환
        const formatted = (number / 1000000)?.toFixed(1);
        return formatted.endsWith(".0") ? formatted.slice(0, -2) + "M" : formatted + "M";
    }
}
export function formatInstagramKorean(number) {
    number = parseFloat(number?.toString()?.replace(/,/g, "")) || 0;
    if (number < 10000) {
        // 10000 미만의 경우 그대로 반환
        return number.toLocaleString();
    } else {
        // 10000 이상인 경우 "만"을 붙여 반환
        const formatted = (number / 10000)?.toFixed(1);
        return formatted.endsWith(".0") ? formatted.slice(0, -2) + "만" : formatted + "만";
    }
}

export function processText(inputText) {
    const valueMatch = inputText?.match(/([0-9.]+)([kmKM]?)/);

    if (valueMatch) {
        const numericValue = parseFloat(valueMatch[1]);
        const unit = valueMatch[2];

        if (unit === "K") {
            return inputText;
        } else if (unit === "M") {
            return inputText;
        } else {
            return formatInstagram(inputText);
        }
    } else {
        return "0";
    }
}

export function parseInstagramCount(countString) {
    const multipliers = {
        K: 1000,
        M: 1000000,
    };

    const match = countString?.match(/^(\d*\.?\d*)([K|M])?$/);

    if (!match) {
        return NaN;
    }

    const [, value, unit] = match;
    const multiplier = multipliers[unit] || 1;

    return parseFloat(value) * multiplier;
}

import User1 from "../../assets/images/user1.png";
import User2 from "../../assets/images/user2.png";
import User3 from "../../assets/images/user3.png";
import User4 from "../../assets/images/user4.png";
export const getRandomFallbackImage = () => {
    const imageUrls = [User1, User2, User3, User4];
    const randomIndex = Math.floor(Math.random() * imageUrls?.length);
    return imageUrls[randomIndex];
};

export function formatPercent(value) {
    const formattedValue = parseFloat(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        // maximumSignificantDigits: 3,
    });
    return `${formattedValue}%`;
}
export function calculateAverage(value1, value2) {
    const average = (value1 + value2) / 2;
    const cappedAverage = Math.min(average, 100); // 최대값이 100을 넘지 않도록 제한
    return cappedAverage;
}

// 문자열에서 , k와 m을 숫자로 변환하여 계산하는 함수
function convertNumber(input) {
    // 입력 값을 문자열로 변환
    const inputStr = input?.toString();
    // 쉼표 제거
    const cleanedInput = inputStr?.replace(/,/g, "");
    // K 또는 M을 포함하는 경우 변환
    if (cleanedInput?.includes("K")) {
        const num = parseFloat(cleanedInput);
        if (!isNaN(num)) {
            return num * 1000;
        }
    } else if (cleanedInput?.includes("M")) {
        const num = parseFloat(cleanedInput);
        if (!isNaN(num)) {
            return num * 1000000;
        }
    } else {
        const num = parseFloat(cleanedInput);
        if (!isNaN(num)) {
            return num;
        }
    }
    // 변환되지 않은 경우 그대로 반환
    return input;
}
export function interactionTotal(value1, value2) {
    const average = (convertNumber(value1) / convertNumber(value2)) * 100;
    const cappedAverage = Math.min(average, 100)?.toFixed(1); // 최대값이 100을 넘지 않도록 제한
    return cappedAverage;
}

// 팔로워수, 미디어수 증가율 계산
export const followerGrahpMake = (value1, value2, value3, value4) => {
    // console.log("증가율", value1, value2, value3, value4);
    // value1:최소 팔로워수
    const previousMonthFollowersNumber = convertNumber(value1);
    // value2: 최대 팔로워수
    const currentMonthFollowersNumber = convertNumber(value2);
    // value3: 최소 미디어수
    const previousMonthMediaNumber = convertNumber(value3);
    const currentMonthMediaNumber = convertNumber(value4);
    // console.log(previousMonthFollowersNumber, currentMonthFollowersNumber, previousMonthMediaNumber, currentMonthMediaNumber);
    // 팔로워 계산
    const totalFollower = currentMonthFollowersNumber / previousMonthFollowersNumber;
    // 미디어 계산
    const totalMedia = currentMonthMediaNumber / previousMonthMediaNumber;
    return Math.min(totalFollower + totalMedia, 100)?.toFixed(1);
    // return (totalFollower + totalMedia).toFixed(2);

    // return currentMonthFollowersNumber / previousMonthFollowersNumber;
};

// NaN 인지 체크
export function checkNaN(value) {
    if (isNaN(value)) {
        return 0;
    } else {
        return value;
    }
}

export function checkCapsLock(event) {
    return event.getModifierState("CapsLock");
}

export function convertNewlineToBr(text) {
    return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}
