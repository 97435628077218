import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import AxiosPost from "components/untils/AxiosPost";
import { useSelector } from "react-redux";
import PaginationBox from "components/untils/PaginationBox";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCampaignType } from "components/common/SelectData";
import moment from "moment";
const itemsPerPage = 5; // 페이지당 아이템 개수

const TemporayCampaign = () => {
    const navigate = useNavigate();
    const loginSeq = useSelector((state) => state.auth.seq);
    const [myCampaignList, setMyCampaignList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const fetchData = () => {
        let page = (currentPage - 1) * itemsPerPage;
        let rowCount = itemsPerPage;
        AxiosPost("webcampaign/temporayList", { client_id: loginSeq, page: page, rowCount: rowCount })
            .then((res) => {
                // console.log("마이 캠페인 --- ", res?.data?.data);
                if (res?.data?.result === "success") {
                    setMyCampaignList(res?.data?.data?.rows);
                    setTotalCount(res?.data?.data?.count);
                }
            })
            .catch((error) => console.log(error));
    };

    const goRegisterPage = (campaignNum) => {
        navigate("/registercampaign", {
            state: {
                campaignNum: campaignNum,
            },
        });
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="settlement_dtls mtb190">
                <div className="container">
                    <h2 className="inner_heading">임시저장 캠페인</h2>

                    <div className="table-responsive">
                        <Table striped className="text-center">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>캠페인 제목</th>
                                    <th>캠페인 타입</th>
                                    <th>마지막 저장 날짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myCampaignList &&
                                    myCampaignList?.map((item) => {
                                        return (
                                            <tr key={item?.campaign_id} onClick={() => goRegisterPage(item?.campaign_id)}>
                                                <td>{item?.campaign_id}</td>
                                                <td>{item?.campaign_name}</td>
                                                <td>{selectCampaignType(item?.campaign_type)}</td>
                                                <td>{moment(item?.update_at).format("YYYY-MM-DD HH:mm")}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationBox totalCount={totalCount} itemsPerPage={itemsPerPage} onPageChange={onPageChange} />
                </div>
            </div>
        </Fragment>
    );
};

export default TemporayCampaign;
