import React, { useState } from "react";
import { ResponsivePie } from "@nivo/pie";

function FollowerGenderGraph({ lady, man }) {
    // 성별 데이터 퍼센트로 비율 계산
    const rate = { lady: lady ? parseInt(lady) : 0, man: man ? parseInt(man) : 0 };

    // 전체 합계 계산
    const total = Object.values(rate).reduce((sum, value) => sum + value, 0);
    // 각 값을 퍼센트로 변환
    const percentages = {};
    for (const key in rate) {
        percentages[key] = Math.round((rate[key] / total) * 100);
    }

    let data = [
        {
            id: "여성",
            label: "여성",
            value: lady ? parseInt(lady) : 0,
            color: "#4e78e8",
            percent: percentages["lady"],
        },
        {
            id: "남성",
            label: "남성",
            value: man ? parseInt(man) : 0,
            color: "#cfcfcf",
            percent: percentages["man"],
        },
    ];
    return (
        <div style={{ width: "210px", height: "155px" }}>
            <ResponsivePie
                data={data}
                margin={{ top: 20, right: 40, bottom: 25, left: 35 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                colors={(e) => e.data.color}
                arcLinkLabel={(e) => (
                    <tspan>
                        {e?.id}
                        <tspan dy="1em" x="0">
                            {e?.data?.percent}%
                        </tspan>
                    </tspan>
                )}
                arcLinkLabelsTextColor={{ from: "color", modifiers: [] }}
                arcLinkLabelsColor={{ from: "color", modifiers: [] }}
                arcLinkLabelsSkipAngle={6}
                enableArcLabels={false}
                arcLinkLabelsOffset={-6}
                arcLinkLabelsDiagonalLength={11}
                arcLinkLabelsStraightLength={15}
                arcLinkLabelsThickness={2}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                animate={false}
                isInteractive={false}
                fill={[
                    {
                        match: {
                            id: "여성",
                        },
                        id: "여성",
                    },
                    {
                        match: {
                            id: "남성",
                        },
                        id: "남성",
                    },
                ]}
                legends={[]}
            />
        </div>
    );
}

export default FollowerGenderGraph;
