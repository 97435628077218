import React, { useState } from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import LoginModal from "components/LoginModal/LoginModal";

const FindPwdResult = () => {
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    return (
        <Fragment>
            <div className="hr_line"></div>
            <LoginModal show={modalShow} handleClose={handleClose} />
            <div className="register_form mtb190">
                <div className="container">
                    <div className="findresult_panel_ad">
                        <div className="findresults">
                            <p>비밀번호가 변경되었습니다. 새로운 비밀번호로 로그인해주세요.</p>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8 text-center">
                                {" "}
                                <Button type="submit" className="btn_layout" onClick={() => setModalShow(true)}>
                                    로그인
                                </Button>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FindPwdResult;
