import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import AxiosPost from "components/untils/AxiosPost";

const FAQ = () => {
    const [account, setAccount] = useState(null);
    const [payment, setPayment] = useState(null);
    const [campain, setCampain] = useState(null);
    const [platform, setPlatform] = useState(null);

    // 카테고리 - 계정
    useEffect(() => {
        AxiosPost("faq/list", { category: "캠페인 서비스" }).then((res) => {
            // console.log("계정 --- ", res.data);
            if (res?.data?.result === "success") {
                setAccount(res?.data?.data);
            } else {
                setAccount(null);
            }
        });
    }, []);

    // 카테고리 - 결제
    useEffect(() => {
        AxiosPost("faq/list", { category: "캠페인 비용" }).then((res) => {
            // console.log("결제 --- ", res.data);
            if (res?.data?.result === "success") {
                setPayment(res?.data?.data);
            } else {
                setPayment(null);
            }
        });
    }, []);

    // 카테고리 - 플랫폼 기술
    useEffect(() => {
        AxiosPost("faq/list", { category: "플랫폼 기술" }).then((res) => {
            // console.log("캠페인등록 --- ", res.data);
            if (res?.data?.result === "success") {
                setPlatform(res?.data?.data);
            } else {
                setPlatform(null);
            }
        });
    }, []);

    // 카테고리 - 캠페인등록
    useEffect(() => {
        AxiosPost("faq/list", { category: "캠페인 진행" }).then((res) => {
            // console.log("캠페인등록 --- ", res.data);
            if (res?.data?.result === "success") {
                setCampain(res?.data?.data);
            } else {
                setCampain(null);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="faq_panel mtb190">
                <div className="container">
                    <h2 className="inner_heading">자주하는 질문</h2>
                    <Accordion alwaysOpen>
                        <div className="faq_details">
                            <h3>캠페인 서비스</h3>
                            {account?.map((data) => {
                                return (
                                    <Accordion.Item eventKey={data?.id} key={data?.id}>
                                        <Accordion.Header>{data?.title}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: data?.content }}></Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </div>

                        <div className="faq_details">
                            <h3>캠페인 비용</h3>
                            {payment?.map((data) => {
                                return (
                                    <Accordion.Item eventKey={data?.id} key={data?.id}>
                                        <Accordion.Header>{data?.title}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: data?.content }}></Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </div>

                        <div className="faq_details">
                            <h3>플랫폼 기술</h3>
                            {platform?.map((data) => {
                                return (
                                    <Accordion.Item eventKey={data?.id} key={data?.id}>
                                        <Accordion.Header>{data?.title}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: data?.content }}></Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </div>

                        <div className="faq_details">
                            <h3>캠페인 진행</h3>
                            {campain?.map((data) => {
                                return (
                                    <Accordion.Item eventKey={data?.id} key={data?.id}>
                                        <Accordion.Header>{data?.title}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: data?.content }}></Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </div>

                        {/* <Accordion defaultActiveKey={["0"]} alwaysOpen> */}
                    </Accordion>

                    <div className="row mb-3 mt-5">
                        <div className="d-flex justify-content-center">
                            <Link to="/contactus" className="btn_layout col-auto " onClick={scrollToTop}>
                                문의하기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FAQ;
