import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import { checkBoxDatas } from "components/db/CategoryCheckBox";
import AxiosPost from "components/untils/AxiosPost";
import { ErrorCodeList } from "components/common/errorCodeList";

function TypeEditModal({ datas, setEffect, clientId }) {
    const ref = useRef(null);
    const [eachCheck, setEachCheck] = useState([]);

    useEffect(() => {
        if (datas.length) {
            setEachCheck(datas.map((d) => d.criteria_type));
        }
    }, [datas]);

    const onCheck = (checked, name, e) => {
        if (checked) {
            if (eachCheck.length < 3) {
                setEachCheck([...eachCheck, name]);
            } else {
                alert(ErrorCodeList.msg711);
                e.target.checked = false;
            }
        } else if (!checked) {
            setEachCheck(eachCheck.filter((el) => el !== name));
        }
    };

    // const onCheck = (checked, name, e) => {
    //     if (checked) {
    //         setEachCheck([...eachCheck, name]);
    //     } else if (!checked) {
    //         setEachCheck(eachCheck.filter((el) => el !== name));
    //     }
    //     if (eachCheck.length > 2 && checked === true) {
    //         alert("최대 3개까지 선택 가능");
    //         setEachCheck(eachCheck.filter((el) => el !== name));
    //         e.target.value = false;
    //     }
    // };

    const onSubmit = () => {
        if (!eachCheck.length) {
            alert(ErrorCodeList.msg712);
        } else {
            AxiosPost("client/user/editClientCriteriaType", { criteria_type: eachCheck, client_id: clientId }).then((res) => {
                if (res?.data?.result === "success") {
                    setEffect(true);
                    ref.current.click();
                } else {
                    alert(ErrorCodeList.msg713);
                }
            });
        }
    };

    return (
        <div className="modal fade user_modal  modal-lg" id="businessTypeModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content  text-center">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            취급 업종 수정하기
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} style={{ display: "none" }}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-5 multi_check_box">
                            <div className="col-sm-12 text-start">
                                {checkBoxDatas.map((item) => {
                                    return (
                                        <div className="form-check form-check-inline " key={item.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name={item.label}
                                                id={item.label}
                                                value={item.label || ""}
                                                onChange={(e) => onCheck(e.target.checked, item.label, e)}
                                                checked={eachCheck.includes(item.label)}
                                            />
                                            <label className="form-check-label" htmlFor={item.label}>
                                                {item.label}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-sm-12">
                                <Button className="save_btn btn_layout active" onClick={() => onSubmit()}>
                                    저장
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TypeEditModal;
