// 선택할 수 있는 최소 날짜
export const GetMinDate = (dateEnd, days) => {
    if (dateEnd) {
        const minDateValue = new Date(dateEnd);
        minDateValue.setDate(minDateValue.getDate() + days); // 선택한 시작 날짜에서 2일을 더함
        return minDateValue.toISOString().split("T")[0];
    } else {
        return null;
    }
};
// 오늘 날짜 구하기
export const todayDate = () => {
    const today = new Date();
    const minDate = today.toISOString().split("T")[0];
    return minDate;
};
