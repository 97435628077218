import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const Analytics = () => {
    const location = useLocation();
    useEffect(() => {
        if (process.env.REACT_APP_GTAG_ID) {
            ReactGA.initialize(process.env.REACT_APP_GTAG_ID);
            ReactGA.send({ hitType: "pageview", page: location.pathname, location: location.pathname, title: location.pathname });
        }
    }, [location]);
    return <></>;
};

export default Analytics;
