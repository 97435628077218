import React, { useState } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import "../../assets/style/customRadarChart.css";

function RadarChart({ userInfo }) {
    let username = userInfo?.name;
    const settingData = [];
    let scoreName = ["호감도", "반응율", "전문성", "성실도", "컨텐츠"];
    for (let gradeScore of scoreName) {
        let scoreDist = {};
        if (gradeScore === "호감도") {
            scoreDist["id"] = 1;
            scoreDist["score"] = "호감도";
            scoreDist["username"] = isNaN(userInfo?.preference_score) ? 0 : userInfo?.preference_score === 0 ? 7 : userInfo?.preference_score?.toString();
            // scoreDist["averuser"] = isNaN(userInfo?.preference_score_avg) ? 0 : userInfo?.preference_score_avg?.toString();
            scoreDist["averuser"] = isNaN(userInfo?.preference_score_avg) ? 0 : userInfo?.preference_score_avg === 0 ? 7 : userInfo?.preference_score_avg;
        } else if (gradeScore === "반응율") {
            scoreDist["id"] = 2;
            scoreDist["score"] = "반응율";
            scoreDist["username"] = isNaN(userInfo?.response_rate_score) ? 0 : userInfo?.response_rate_score?.toString();
            scoreDist["averuser"] = isNaN(userInfo?.response_rate_score_avg) ? 0 : userInfo?.response_rate_score_avg;
            // scoreDist["averuser"] = 2.6;
        } else if (gradeScore === "전문성") {
            scoreDist["id"] = 3;
            scoreDist["score"] = "전문성";
            scoreDist["username"] = isNaN(userInfo?.professionalism_score) ? 0 : userInfo?.professionalism_score?.toString();
            scoreDist["averuser"] = isNaN(userInfo?.professionalism_score_avg) ? 0 : userInfo?.professionalism_score_avg;
            // scoreDist["averuser"] = 2;
        } else if (gradeScore === "성실도") {
            scoreDist["id"] = 4;
            scoreDist["score"] = "성실도";
            scoreDist["username"] = isNaN(userInfo?.potential_score) ? 0 : userInfo?.potential_score?.toString();
            scoreDist["averuser"] = isNaN(userInfo?.potential_score_avg) ? 0 : userInfo?.potential_score_avg;
            // scoreDist["averuser"] = 2.5;
        } else if (gradeScore === "컨텐츠") {
            scoreDist["id"] = 5;
            scoreDist["score"] = "컨텐츠";
            scoreDist["username"] = isNaN(userInfo?.quality_score) ? 0 : userInfo?.quality_score?.toString();
            scoreDist["averuser"] = isNaN(userInfo?.quality_score_avg) ? 0 : userInfo?.quality_score_avg;
            // scoreDist["averuser"] = 8;
        }
        settingData.push(scoreDist);
    }

    let perferPoint = isNaN(userInfo?.preference_score) ? 0 : userInfo?.preference_score === 0 ? 7 : userInfo?.preference_score;
    let responsePoint = isNaN(userInfo?.response_rate_score) ? 0 : userInfo?.response_rate_score;
    let professPoint = isNaN(userInfo?.professionalism_score) ? 0 : userInfo?.professionalism_score;
    let potentialPoint = isNaN(userInfo?.potential_score) ? 0 : userInfo?.potential_score;
    let qualityPoint = isNaN(userInfo?.quality_score) ? 0 : userInfo?.quality_score;

    let sum = perferPoint + responsePoint + professPoint + potentialPoint + qualityPoint;

    const CustomRadarDots = ({ dataWithPoints }) => {
        return dataWithPoints.map((point, index) => <circle key={index} cx={point.x} cy={point.y} r={5} fill="#FF5733" />);
    };

    const CustomLabel = ({ datum }) => {
        return <>{datum.value}</>;
    };

    const influScore = (datas, id) => {
        return datas?.find((item) => item?.id === id);
    };

    const [tooltipComponent, setTooltipComponent] = useState({}); // 툴팁 내용
    const [isMouseOver, setIsMouseOver] = useState(false); // 마우스 오버 boolean

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    const CustomTooltip = () => {
        return (
            <div className="tooltip-container" style={{ zIndex: 100, position: "absolute", right: 0, top: 0 }}>
                <p className="title-text">{tooltipComponent?.key}</p>
                <p>{tooltipComponent?.customText}</p>
                <p>
                    {" "}
                    인플루언서 : <span style={{ fontWeight: "bold" }}>{tooltipComponent?.influencerScore?.formattedValue}</span>, 평균 :{" "}
                    <span style={{ fontWeight: "bold" }}>{tooltipComponent?.averScore?.formattedValue}</span>
                </p>
            </div>
        );
    };

    const colors = ["#cfcfcf", "#277ae9"];
    if (userInfo) {
        return (
            <div style={{ position: "relative" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div style={{ width: "auto", height: "300px", margin: "0 auto" }}>
                    <div className="radarchart_percentage">
                        <p>인플루언서 평점</p>
                        <h4>{isNaN(sum / 5) ? 0 : (sum / 5).toFixed(2)}</h4>
                    </div>
                    <div style={{ width: "330px", height: "100%", position: "absolute", index: 0 }}>
                        <ResponsiveRadar
                            data={settingData}
                            keys={["averuser", "username"]}
                            indexBy="score"
                            keyProp="id"
                            valueFormat=">-.1f"
                            maxValue={10}
                            margin={{ left: 40, top: 40, bottom: 0, right: 40 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: "color" }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={10}
                            enableDots={true}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: "color" }}
                            enableDotLabel={false}
                            radarDots={CustomRadarDots}
                            dotLabel={(datum) => <CustomLabel datum={datum} />}
                            dotLabelYOffset={-12}
                            colors={colors}
                            fillOpacity={0.4}
                            animate={true}
                            motionConfig="wobbly"
                            isInteractive={true}
                            sliceTooltip={(node) => {
                                // console.log("node", node);
                                const key = node.index;
                                let customText = "";
                                let influencerScore = influScore(node?.data, "username");
                                let averScore = influScore(node?.data, "averuser");
                                if (key === "호감도") {
                                    customText = "이전에 수행한 캠페인의 평가 점수 평점";
                                } else if (key === "반응율") {
                                    customText = "최신 포스팅들의 반응율";
                                } else if (key === "전문성") {
                                    customText = "카테고리 키워드가 최신 포스팅에서 사용된 #의 숫자";
                                } else if (key === "성실도") {
                                    customText = "전달 대비 포스팅, 좋아요, 댓글의 증감수 비교";
                                } else {
                                    customText = "광고 포스팅의 비율";
                                }
                                setTooltipComponent({
                                    key,
                                    customText,
                                    influencerScore,
                                    averScore,
                                });
                                // return (
                                //     <div className="tooltip-container">
                                //         <p className="title-text">{key}</p>
                                //         <p>{customText}</p>
                                //         <p>
                                //             {" "}
                                //             인플루언서 : {influencerScore?.formattedValue}, 평균 : {averScore?.formattedValue}{" "}
                                //         </p>
                                //     </div>
                                // );
                            }}
                        />
                    </div>
                </div>
                {isMouseOver && <CustomTooltip />}
            </div>
        );
    }
}

export default RadarChart;
