import React from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ContactUsModel = ({ show, handleClose, userName }) => {
    return (
        <Modal
            className="modal fade user_modal modal-dialog-centered  modal-lg"
            id="contactusModal"
            show={show}
            onHide={handleClose}
            tabIndex="-1"
            data-toggle="modal"
            dialogClassName="modal-90w"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            centered
        >
            <Modal.Body >
                <div className="modal-body text-center">
                    <p>
                        <b>{userName}</b>님의 문의사항이 전송되었습니다.{" "}
                    </p>
                    <p>최대한 빠른 시간 내에 전달해 주신 이메일로 답변 드리겠습니다.</p>
                    <p>감사합니다 :)</p>

                    <div className="row mb-3 mt-5">
                        <div className="d-flex justify-content-center">
                            <Button type="submit" className="btn_layout col-auto active contact_us_btn" onClick={handleClose}>
                                확인
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content  text-center">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            <b>(담당자명)</b>님의 문의사항이 전송되었습니다.{" "}
                        </p>
                        <p>최대한 빠른 시간 내에 답변을 전달해 주신 이메일로 드리겠습니다.</p>
                        <p>감사합니다 :)</p>

                        <div className="row mb-3">
                            <div className="d-flex justify-content-center">
                                <Button type="submit" className="btn btn-primary col-auto " onClick={handleClose}>
                                    확인
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Modal>
    );
};

export default ContactUsModel;
