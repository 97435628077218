import React from "react";
import { useState, useEffect } from "react";
import ToButtonImg from "assets/images/btn_top.png";
import Button from "react-bootstrap/Button";

function TopButton() {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 500) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener("scroll", handleShowButton);
        return () => {
            window.removeEventListener("scroll", handleShowButton);
        };
    }, []);

    return (
        showButton && (
            <div className="scroll__container">
                <Button id="top" onClick={scrollToTop} type="button">
                    <img src={ToButtonImg} alt="top" />
                </Button>
            </div>
        )
    );
}

export default TopButton;
