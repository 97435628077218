import { createStore, applyMiddleware, compose } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import authSlice from "./userSlice";
import storageSession from "redux-persist/lib/storage/session";
import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from "redux-state-sync";

const reducers = combineReducers({
    auth: authSlice.reducer,
});

const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: ["auth"],
};

// const enhancer =
//     process.env.NODE_ENV === "production"
//         ? compose(
//               applyMiddleware(
//                   createStateSyncMiddleware({
//                       blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
//                   }),
//                   thunk,
//                   promiseMiddleware
//               )
//           )
//         : composeWithDevTools(
//               applyMiddleware(
//                   createStateSyncMiddleware({
//                       blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
//                   }),
//                   thunk,
//                   promiseMiddleware
//                   //   logger,
//               )
//           );

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [...getDefaultMiddleware({ serializableCheck: false })];

// const store = configureStore({
//     reducer: persistedReducer,
// });

const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: true,
});

export default store;
