import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";

function GrowthGraph({ twoMonth, lastMonth }) {
    let data = [
        {
            result: "before",
            item: lastMonth,
            color: "#cfcfcf",
        },
        {
            result: "after",
            item: twoMonth,
            color: "#4e78e8",
        },
    ];

    const CustomBar = (props) => {
        let data = props?.bar;
        // console.log("growthGraph -- ", data);
        return (
            <rect x={data?.x} y={data?.y} width={12} height={data?.height < 0 ? 0 : data?.height} fill={data?.data?.indexValue == "before" ? "#cfcfcf" : "#4e78e8"} rx={7} ry={7} />
        );
    };

    return (
        <div style={{ width: "45px", height: "90px" }}>
            <ResponsiveBar
                data={data}
                keys={["item"]}
                indexBy="result"
                padding={0}
                groupMode="grouped"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                borderRadius={8}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 9,
                    tickRotation: 0,
                    legend: "팔로워",
                    legendPosition: "middle",
                    legendOffset: 48,
                    truncateTickAt: 0,
                }}
                axisLeft={null}
                enableGridY={false}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                isInteractive={false}
                barComponent={(props) => <CustomBar {...props} />}
                theme={{
                    fontSize: 13,
                    fontFamily: "sans-serif",
                    textColor: "#000000",
                    axis: {
                        fontSize: "13px",
                        tickColor: "#000000",
                        ticks: {
                            line: {
                                stroke: "#000000",
                            },
                            text: {
                                fill: "#000000",
                            },
                        },
                    },
                }}
                legends={[]}
                role="application"
                ariaLabel="Nivo bar chart demo"
            />
        </div>
    );
}

export default GrowthGraph;
