import React, { Fragment, useState, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { RegisterTemporay } from "./RegisterSave";
import { useNavigate } from "react-router-dom";

import DropZoneList from "components/untils/DropZoneList";

const RegisterMoreInfo = ({ setActiveTab, jsonData, setJsonData }) => {
    const navigate = useNavigate();
    // console.log("넘어온 데이터 확인!!!!!!!!!!!!", jsonData);
    const [selectFiles, setSelectFiles] = useState([]); // 이미지 json 전달
    const [imageFiles, setImageFiles] = useState(jsonData?.campaign_img_previews); // 이미지  dropzone 전달
    let dbIntroduction = jsonData?.introduction?.replace(/<br>/g, "\n"); // textarea <br> => /n으로 변경

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            campaign_name: jsonData?.campaign_name,
            product_detail: jsonData?.product_detail,
            campaign_url: jsonData?.campaign_url,
            introduction: dbIntroduction,
        },
    });

    // 임시 저장 버튼
    const temporayBtn = () => {
        const isSave = confirm("지금까지의 내용을 임시저장 하시겠습니까?");
        if (isSave) {
            const data = getValues();
            let setData = {
                campaign_name: data.campaign_name,
                product_detail: data.product_detail,
                campaign_url: data.campaign_url,
                introduction: data.introduction,
                campaign_images: selectFiles,
                campaign_img_previews: imageFiles,
                tabLevel: "postguide",
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            setJsonData(tempJson);
            RegisterTemporay(tempJson, navigate);
        }
    };

    // 데이터 넘어가는 부분
    const handleNextEvent = () => {
        const data = getValues(); // 폼
        let setData = {
            campaign_name: data.campaign_name,
            product_detail: data.product_detail,
            campaign_url: data.campaign_url,
            introduction: data.introduction,
            campaign_images: selectFiles,
            campaign_img_previews: imageFiles,
            tabLevel: "postguide",
        };
        let tempJson = jsonData;
        for (var key in setData) {
            tempJson[key] = setData[key];
        }
        // console.log("이미지 확인 - ", tempJson);
        // console.log("또 확인 -- ", selectFiles);
        setJsonData(tempJson);
        setActiveTab("postguide");
    };

    const onError = (data) => {
        console.log("onError --- ", data);
    };
    return (
        <Fragment>
            <div className="moreinfo_register_campaign common_review ">
                <div className="container">
                    <form onSubmit={handleSubmit(onError)}>
                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">01</span> 캠페인 제목을 입력해 주세요* (항목은 APP에서 프리미언에게 보여지는 제목입니다.){" "}
                                    <span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input className="form-control" placeholder="50자 이내로 입력해 주세요" {...register("campaign_name", { required: true })} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">02</span> 협찬하는 상품/서비스를 자세히 모두 알려주세요.<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input className="form-control" placeholder="" {...register("product_detail", { required: true })} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">03</span> 상품, 서비스, 브랜드를 잘 알 수 있는 SNS 링크 또는 홈페이지 주소를 알려주세요
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input className="form-control" placeholder="" {...register("campaign_url")} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">04</span> 캠페인의 이해를 도와줄 수 있는 소개 이미지를 업로드해 주세요{" "}
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <DropZoneList imageFiles={imageFiles} selectFiles={selectFiles} setImageFiles={setImageFiles} setSelectFiles={setSelectFiles} />
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">05</span> 캠페인을 자유롭게 소개해 주세요.<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <textarea
                                        className="form-control"
                                        placeholder="- 4줄 이하로 간단하게 작성해 주세요.
-브랜드 및 상품에 대한 주요 장점을 알려주세요.
-이번 캠페인의 중요 마케팅 포인트를 알려주세요."
                                        rows="6"
                                        {...register("introduction", { required: true })}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            {/* <div className="col-sm-6 text-center">
                                <Button type="button" onClick={() => temporayBtn()} className="btn_layout">
                                    임시저장
                                </Button>
                            </div> */}
                            <div className="col-sm-12 text-center">
                                <Button
                                    type="button"
                                    onClick={handleNextEvent}
                                    className={`btn_layout ${isValid ? "" : "un_active"}`}
                                    style={{ padding: "20px 100px" }}
                                    disabled={isValid ? false : true}
                                >
                                    포스팅 가이드 입력하기
                                </Button>
                            </div>
                            <button type="submit" style={{ display: "none" }}></button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default RegisterMoreInfo;
