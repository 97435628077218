import React from "react";
import { ResponsivePie } from "@nivo/pie";

function ReelsPieGraph() {
    let totalData = 200;
    let ReelsData = 60;
    // 성별 데이터 퍼센트로 비율 계산
    const rate = { totalData: totalData ? totalData : 0, reelsData: ReelsData ? ReelsData : 0 };
    // 전체 합계 계산
    const total = Object.values(rate).reduce((sum, value) => sum + value, 0);
    // 각 값을 퍼센트로 변환
    const percentages = {};
    for (const key in rate) {
        percentages[key] = Math.round((rate[key] / total) * 100);
    }

    let data = [
        {
            id: "reels",
            label: "reels",
            value: totalData ? totalData : 0,
            color: "#4e78e8",
            percent: percentages["totalData"],
        },
        {
            id: "total",
            label: "total",
            value: ReelsData ? ReelsData : 0,
            color: "#cfcfcf",
            percent: percentages["reelsData"],
        },
    ];

    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
        let total = 0;
        dataWithArc.forEach((datum) => {
            total += datum.value;
        });

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    fontSize: "18px",
                }}
            >
                {data[0]?.percent}%
            </text>
        );
    };

    return (
        <div style={{ width: "250px", height: "180px" }}>
            <ResponsivePie
                data={data}
                // margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.6}
                padAngle={0.7}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                colors={(e) => e.data.color}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                animate={false}
                isInteractive={false}
                layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
                legends={[]}
            />
        </div>
    );
}

export default ReelsPieGraph;
