import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";

function YregionGraph(dbData) {
    dbData = dbData?.dbData;

    let data = [
        {
            country: "전국",
            donut: dbData["전국"] ? dbData["전국"] : 0,
        },
        {
            country: "서울",
            donut: dbData["서울"] ? dbData["서울"] : 0,
        },
        {
            country: "경기",
            donut: dbData["경기"] ? dbData["경기"] : 0,
        },
        {
            country: "대전",
            donut: dbData["대전"] ? dbData["대전"] : 0,
        },
        {
            country: "부산",
            donut: dbData["부산"] ? dbData["부산"] : 0,
        },
        {
            country: "충청",
            donut: (dbData["충남"] ? dbData["충남"] : 0) + (dbData["충북"] ? dbData["충북"] : 0),
        },
        // {
        //     country: "전라",
        //     donut: (dbData["충남"] ? dbData["충남"] : 0) + (dbData["충북"] ? dbData["충북"] : 0),
        // },
        {
            country: "제주",
            donut: dbData["제주"] ? dbData["제주"] : 0,
        },
    ];

    // 막대에 마우스가 들어갔을 때의 동작
    const handleMouseEnter = (data) => {};

    // 막대에서 마우스가 나갔을 때의 동작
    const handleMouseLeave = () => {};

    const CustomBar = (props) => {
        const [showTooltip, setShowTooltip] = useState(false);
        let onMouseEnter = props?.onMouseEnter;
        let onMouseLeave = props?.onMouseLeave;
        let data = props?.bar;
        return (
            <g
                onMouseEnter={() => {
                    onMouseEnter(data?.data);
                    setShowTooltip(true);
                }}
                onMouseLeave={() => {
                    onMouseLeave();
                    setShowTooltip(false);
                }}
            >
                <rect x={data?.absX} y={data?.absY} width={data?.width} height={data?.height} fill={"#4e78e8"} rx={9} ry={9} />
                {showTooltip && (
                    <text x={data?.absX + data?.width / 2} y={data?.absY + data?.height / 2} textAnchor="middle" dominantBaseline="middle" fill="white">
                        {data?.data?.value}
                    </text>
                )}
            </g>
        );
    };

    return (
        <div style={{ width: "280px", height: "160px" }}>
            <ResponsiveBar
                data={data}
                keys={["donut"]}
                indexBy="country"
                margin={{ bottom: 30 }}
                padding={0.5}
                groupMode="grouped"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={() => "#4e78e8"}
                barWidth={0}
                width={280}
                height={160}
                fill={[
                    {
                        match: {
                            id: "fries",
                        },
                        id: "dots",
                    },
                    {
                        match: {
                            id: "sandwich",
                        },
                        id: "lines",
                    },
                ]}
                borderRadius={9}
                axisTop={null}
                axisRight={null}
                barComponent={(props) => <CustomBar {...props} />}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 32,
                    truncateTickAt: 0,
                }}
                axisLeft={null}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                theme={{
                    fontSize: 12,
                    fontFamily: "sans-serif",
                    textColor: "#000000",
                    axis: {
                        fontSize: "12px",
                        tickColor: "#000000",
                        ticks: {
                            line: {
                                stroke: "#000000",
                            },
                            text: {
                                fill: "#000000",
                            },
                        },
                    },
                }}
                legends={[]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
            />
        </div>
    );
}

export default YregionGraph;
