import React, { Fragment, useState, useEffect } from "react";
import { selectCampaignType, selectChannelValue, selectContentsType } from "components/common/SelectData";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { addCommas } from "components/common/common";

function CheckRequestPart({ jsonData, editEffect, setEditResult }) {
    const [checkData, setCheckData] = useState(null);
    const [dateStart, setDateStart] = useState(jsonData?.campaign_postingperiod[0].start_date);
    const [dateEnd, setDateEnd] = useState(jsonData?.campaign_postingperiod[0].end_date);
    const [postingStart, setPostingStart] = useState(jsonData?.campaign_postingperiod[1].start_date);
    const [postingEnd, setPostingEnd] = useState(jsonData?.campaign_postingperiod[1].end_date);

    useEffect(() => {
        setCheckData(jsonData);
        setDateStart(jsonData?.campaign_postingperiod[0].start_date);
        setDateEnd(jsonData?.campaign_postingperiod[0].end_date);
        setPostingStart(jsonData?.campaign_postingperiod[1].start_date);
        setPostingEnd(jsonData?.campaign_postingperiod[1].end_date);
    }, [editEffect]);

    return (
        <Fragment>
            <div className="campaign_request_detail">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <h4>캠페인 유형</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>{selectCampaignType(checkData?.campaign_type)}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>진행 프리미언 유형</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>{selectChannelValue(checkData?.campaign_channel)}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>캠페인 진행 방식 및 명수</h4>
                        </div>
                        <div className="col-sm-4">
                            <p>{checkData?.progress_type === "P" ? "상품으로만 진행" : "추천 프리미언"}</p>
                        </div>
                        <div className="col-sm-4">
                            <p>
                                {checkData?.progress_type === "R" && addCommas(checkData?.campaign_point) + "포인트"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {checkData?.campaign_counter}명
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>캠페인 제목</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>{checkData?.campaign_name}</p>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-sm-4">
                            <h4>캠페인 기간</h4>
                        </div>
                        <div className="col-sm-8 campaign_period">
                            <div className="row">
                                <div className="col-sm-5">
                                    <p>프리미언 모집 기간</p>
                                </div>
                                <div className="col-sm-7">
                                    <p>
                                        {moment(dateStart).format("YYYY-MM-DD")} ~ {moment(dateEnd).format("YYYY-MM-DD")}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <p>포스팅 기간</p>
                                </div>
                                <div className="col-sm-7">
                                    <p>
                                        {moment(postingStart).format("YYYY-MM-DD")} ~ {moment(postingEnd).format("YYYY-MM-DD")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>콘텐츠 유형</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>{selectContentsType(checkData?.contents_type)}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>촬영 방법</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>{checkData?.photography_preference}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>포함 필수 키워드</h4>
                        </div>
                        <div className="col-sm-8">
                            <p style={{ whiteSpace: "pre" }}>{checkData?.text_requirements}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h4>필수 해시태그</h4>
                        </div>
                        <div className="col-sm-8">
                            <p>
                                {checkData?.campaign_hashtag?.hashtag1 ? "#" + checkData?.campaign_hashtag?.hashtag1 : ""} &nbsp;
                                {checkData?.campaign_hashtag?.hashtag2 ? "#" + checkData?.campaign_hashtag?.hashtag2 : ""} &nbsp;
                                {checkData?.campaign_hashtag?.hashtag3 ? "#" + checkData?.campaign_hashtag?.hashtag3 : ""} &nbsp;
                                {checkData?.campaign_hashtag?.hashtag4 ? "#" + checkData?.campaign_hashtag?.hashtag4 : ""}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row edit_campaignrq">
                <div className="col-sm-12 text-center ">
                    <Button className="btn_layout" onClick={() => setEditResult(true)}>
                        수정하기
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}

export default CheckRequestPart;
