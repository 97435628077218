import React, { Fragment, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import { FatchPost } from "components/untils/FatchPost";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function InfluencerProductShipping({ datas, campaignId, setCurrentEffect, setDataEffect, setCurrentType, nextEffect, setNextEffect, currentType, loadCurrent }) {
    const [influDatas, setInfluDatas] = useState([]);
    const [inputValues, setInputValues] = useState([]);
    const [selectValues, setSelectValues] = useState([]);

    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };
    const handleSelectChange = (index, value) => {
        const newSelectValues = [...selectValues];
        newSelectValues[index] = value;
        setSelectValues(newSelectValues);
    };

    function isEven(index) {
        return index % 2 === 0; // 나머지가 0이면 짝수, 아니면 홀수
    }

    useEffect(() => {
        AxiosPost("webcampaign/selectInfluencerList", { campaign_id: campaignId, draft: "Y" })
            .then((res) => {
                // setInfluDatas(res?.data?.data);
                let setData = res?.data?.data;
                let processData = setData;
                // 캠페인이 하나도 없을때만 데모 데이터가 보이게
                if (setData?.length > 1) {
                    processData = setData?.filter((item) => item.selected === 1);
                    setInfluDatas(processData);
                } else {
                    setInfluDatas([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [nextEffect, currentType]);

    const handleSaveExcel = () => {
        const selectedData = influDatas.map((item, index) => ({
            이름: item.name,
            ID: item.instagram_username,
            전화번호: item.phone_num,
            배송정보: item.address,
            택배사: selectValues[index],
            송장번호: inputValues[index],
        }));
        const jsonData = [...selectedData];

        const sheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        // 엑셀 파일 생성
        const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        // 파일 다운로드
        const blob = new Blob([excelFile], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "제품배송정보.xlsx");
    };
    const saveNextBtn = () => {
        const inputNullCheck = inputValues.includes("");
        if (inputNullCheck === true) {
            alert("송장번호를 입력해 주세요.");
        } else if (selectValues.length === 0) {
            alert("택배사를 선택해 주세요.");
        } else {
            influDatas.map((item, index) => {
                AxiosPost("influencer/parcelUpdate", {
                    campaignId: campaignId,
                    influencerId: item.influencer_id,
                    parcelCompnay: selectValues[index],
                    parcelNumber: inputValues[index],
                })
                    .then((res) => {
                        AxiosPost("webcampaign/updateStatus", { campaign_id: campaignId, currentStatus: "posting" }).then((res) => {
                            if (res?.data?.result === "success") {
                                setCurrentEffect(true);
                                setNextEffect(true);
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    };

    return (
        <Fragment>
            <div className="settlement_dtls product_shipping">
                <div className="table-responsive">
                    <Table striped className="text-center">
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>이름</th>
                                <th>ID</th>
                                <th>전화번호</th>
                                <th>배송 정보 </th>
                                <th>택배사</th>
                                <th>송장번호</th>
                            </tr>
                        </thead>
                        <tbody>
                            {influDatas &&
                                influDatas?.map((data, index) => {
                                    return (
                                        <tr key={data?.influencer_id}>
                                            <td>{influDatas?.length - index}</td>
                                            <td>{data?.name}</td>
                                            <td>{data?.instagram_username ? "@" + data?.instagram_username : ""}</td>
                                            <td>{data?.phone_num}</td>
                                            <td>{data?.address?.includes("undefined") ? "" : data?.address}</td>
                                            <td>
                                                <select className="form-control" value={selectValues[index]} onChange={(e) => handleSelectChange(index, e.target.value)}>
                                                    <option value="">택배회사선택</option>
                                                    <option value="CJ대한통운">CJ대한통운</option>
                                                    <option value="우체국택배">우체국택배</option>
                                                    <option value="한진택배">한진택배</option>
                                                    <option value="롯데택배">롯데택배</option>
                                                    <option value="로젠택배">로젠택배</option>
                                                    <option value="GS25 편의점택배">GS25 편의점택배</option>
                                                    <option value="CU 편의점택배">CU 편의점택배</option>
                                                    <option value="경동택배">경동택배</option>
                                                    <option value="대신택배">대신택배</option>
                                                    <option value="일양로지스">일양로지스</option>
                                                    <option value="협동택배">협동택배</option>
                                                    <option value="EMS">EMS</option>
                                                    <option value="UPS">UPS</option>
                                                    <option value="FEDEX">FEDEX</option>
                                                    <option value="농협택배">농협택배</option>
                                                    <option value="홈픽">홈픽</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="인보이스 번호 입력"
                                                    style={{ backgroundColor: isEven(index) ? "" : "#F7F7F7" }}
                                                    value={inputValues[index] || ""}
                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </div>

                {influDatas?.length > 0 && (
                    <div className="col-sm-12 mt-4">
                        <Button className="dwnload_exl" onClick={handleSaveExcel}>
                            배송정보 Excel 다운로드
                        </Button>
                    </div>
                )}

                <div className="col-sm-12 text-center mt-2">
                    <Button className="btn_layout" onClick={saveNextBtn} disabled={loadCurrent !== "shipping"}>
                        포스팅 현황
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}

export default InfluencerProductShipping;
