import React, { useEffect, useState } from "react";
import axios from "axios";

function UserUpdateBtn({ dataName, updateName, setName, nameResult }) {
    const updateBtn = (updateType) => {
        if (!updateType) {
            return false;
        } else {
            let jsonTitle = updateType;
            axios.post(process.env.REACT_APP_API + "client/user/infoUpdate", {
                jsonTitle: dataName,
                clientId: 2008,
            });
        }
    };

    return (
        <>
            {nameResult ? (
                <button
                    onClick={() => {
                        updateBtn(updateName);
                        setName(false);
                    }}
                >
                    저장
                </button>
            ) : (
                <button onClick={() => setName(true)}>수정</button>
            )}
        </>
    );
}

export default UserUpdateBtn;
