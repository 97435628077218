import axios from "axios";
export const AxiosPosts = async (url, postData) => {
    return await axios
        .post(process.env.REACT_APP_API + url, postData)
        .then((res) => res)
        .catch((error) => console.error(error));
};

export const FatchPost = (url, postData) => {
    return axios.post(process.env.REACT_APP_API + url, postData);
};
