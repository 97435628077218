import React from "react";
import { ResponsivePie } from "@nivo/pie";

function RoundGenderGraph(dbData) {
    // 성별 데이터 퍼센트로 비율 계산
    const rate = { lady: dbData?.dbData["여성"] ? dbData?.dbData["여성"] : 0, man: dbData?.dbData["남성"] ? dbData?.dbData["남성"] : 0 };
    // 전체 합계 계산
    const total = Object.values(rate).reduce((sum, value) => sum + value, 0);
    // 각 값을 퍼센트로 변환
    const percentages = {};
    for (const key in rate) {
        percentages[key] = Math.round((rate[key] / total) * 100);
    }

    let data = [
        {
            id: "여성",
            label: "여성",
            value: dbData?.dbData["여성"] ? dbData?.dbData["여성"] : 0,
            color: "#4e78e8",
            percent: percentages["lady"],
        },
        {
            id: "남성",
            label: "남성",
            value: dbData?.dbData["남성"] ? dbData?.dbData["남성"] : 0,
            color: "#000000",
            percent: percentages["man"],
        },
    ];
    return (
        <div style={{ width: "300px", height: "180px" }}>
            <ResponsivePie
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                colors={(e) => e.data.color}
                arcLinkLabel={(e) => `${e.id} ${e?.data?.percent}%`}
                arcLinkLabelsTextColor={{ from: "color", modifiers: [] }}
                arcLinkLabelsColor={{ from: "color", modifiers: [] }}
                arcLinkLabelsSkipAngle={12}
                enableArcLabels={false}
                arcLinkLabelsOffset={-12}
                arcLinkLabelsDiagonalLength={20}
                arcLinkLabelsStraightLength={26}
                arcLinkLabelsThickness={3}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                animate={false}
                isInteractive={false}
                // tooltip={(e) => {
                //     // console.log(e);
                //     let value = e.value;
                //     return <div>{value}</div>;
                // }}
                fill={[
                    {
                        match: {
                            id: "여성",
                        },
                        id: "여성",
                    },
                    {
                        match: {
                            id: "남성",
                        },
                        id: "남성",
                    },
                ]}
                legends={[]}
            />
        </div>
    );
}

export default RoundGenderGraph;
