import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserUpdateBtn from "components/User/UserUpdateBtn";
import AxiosPost from "components/untils/AxiosPost";
import Button from "react-bootstrap/Button";
import PhoneEditModal from "components/modals/Users/PhoneEditModal";
import TypeEditModal from "components/modals/Users/TypeEditModal";
import CompanyNumberEditModal from "components/modals/Users/CompanyNumberEditModal";
import { setLogout } from "redux/store/userSlice";
import { persistor } from "../../index";
import { removeCookie } from "components/untils/Cookie";

function UserInfo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientId = useSelector((state) => state.auth.seq);
    const clientEmail = useSelector((state) => state.auth.email);
    const [userInfo, setUserInfo] = useState(null);
    const [managerName, setManagerName] = useState(null);
    const [managerNameElse, setManagerNameElse] = useState(false);
    const [contact, setContact] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyNameElse, setCompanyNameElse] = useState(false);
    const [companyUrl, setComapnyUrl] = useState(null);
    const [companyUrlElse, setComapnyUrlElse] = useState(false);
    const [registrationNum, setRegistrationNum] = useState(null);
    const [registrationDate, setRegistrationDate] = useState(null);
    const [effect, setEffect] = useState(false);
    const [userPwd, setUserPwd] = useState(null);
    const [userPwdElse, setUserPwdElse] = useState(false);
    const [companyType, setCompanyType] = useState(null);
    const [regnumActive, setRegnumActive] = useState(null);
    const [category, setCategory] = useState(null);

    // 매니저 이름 수정
    const managerNameUpdate = () => {
        if (!managerNameElse) {
            return false;
        } else {
            AxiosPost("client/user/editClientName", { manager_name: managerName, client_id: clientId });
        }
    };
    // 회사 url 수정
    const companyUrlUpdate = () => {
        if (!companyUrlElse) {
            return false;
        } else {
            AxiosPost("client/user/editClientUrl", { company_url: companyUrl, client_id: clientId });
        }
    };

    // 회사이름 수정
    const companyNameUpdate = () => {
        if (!companyNameElse) {
            return false;
        } else {
            AxiosPost("client/user/editClientCompanyName", { company_name: companyName, client_id: clientId });
        }
    };
    // 비밀번호 수정
    const userPwdUpdate = () => {
        if (!userPwdElse) {
            return false;
        } else {
            AxiosPost("client/user/changePwd", { newPwd: userPwd, client_id: clientId });
        }
    };

    // 계정삭제
    const removeUser = () => {
        if (confirm("thePremain에 등록된 현재 계정이 삭제됩니다.  \n보유하신 금액은 계정 삭제 후 어떠한 경우에도 환불될 수 없으니, \n반드시 확인해 주세요. ")) {
            AxiosPost("client/user/removeClientUser", {
                client_id: clientId,
                client_email: clientEmail,
            }).then((res) => {
                if (res?.data?.result === "success") {
                    dispatch(setLogout());
                    persistor.purge();
                    removeCookie("token");
                    navigate("/");
                }
            });
        }
    };

    useEffect(() => {
        axios.post(process.env.REACT_APP_API + "client/user/info", { client_id: clientId }).then((res) => {
            const ResultMap = res.data?.data?.criteria_info?.map((x) => {
                return x.criteria_type;
            });
            setCategory(ResultMap);
            setUserInfo(res?.data);
            setRegnumActive(res?.data?.data?.rows?.regnum_active);
            setManagerName(res?.data?.data?.rows?.manager_name);
            setContact(res?.data?.data?.rows?.manager_contact);
            setCompanyName(res?.data?.data?.rows?.company_name);
            setComapnyUrl(res?.data?.data?.rows?.company_url);
            setRegistrationNum(res?.data?.data?.rows?.registration_number);
            setCompanyType(res?.data?.data?.criteria_info);
            setRegistrationDate(res?.data?.data?.rows?.regnum_time);
            setEffect(false);
        });
    }, [effect]);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="userinfo mtb190">
                <div className="container">
                    <h2 className="inner_heading">계정 정보</h2>

                    <div className="userinfo_data">
                        <div className="row  mb-3">
                            <div className="col-sm-6">
                                <label className="form-label">이메일</label>
                                <div className="input-group">
                                    <input className="form-control" defaultValue={userInfo?.data?.rows?.email || ""} disabled readOnly />
                                    <div className="input-group-text invisible">Edit</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">담당자</label>
                                <div className="input-group">
                                    <input className="form-control" defaultValue={managerName || ""} onChange={(e) => setManagerName(e.target.value)} readOnly={!managerNameElse} />

                                    <div className="col-sm-2 edit_save_btn">
                                        {managerNameElse ? (
                                            <Button
                                                className="btn btn-primary save_btn1"
                                                onClick={() => {
                                                    managerNameUpdate();
                                                    setManagerNameElse(false);
                                                }}
                                            >
                                                저장
                                            </Button>
                                        ) : (
                                            <Button className="btn btn-primary " onClick={() => setManagerNameElse(true)}>
                                                수정
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-3">
                            <div className="col-sm-6">
                                <label className="form-label">비밀번호</label>
                                <div className="input-group">
                                    {userPwdElse ? (
                                        <input type="password" className="form-control" defaultValue={userPwd || ""} onChange={(e) => setUserPwd(e.target.value)} />
                                    ) : (
                                        <input type="password" className="form-control" defaultValue="12345678" readOnly />
                                    )}
                                    <div className="col-sm-2  edit_save_btn">
                                        {userPwdElse ? (
                                            <Button
                                                className="save_btn1"
                                                onClick={() => {
                                                    userPwdUpdate();
                                                    setUserPwdElse(false);
                                                }}
                                            >
                                                저장
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setUserPwdElse(true)}>수정</Button>
                                        )}
                                    </div>
                                </div>
                                {userPwdElse && (
                                    <span className="form-text text-danger">
                                        *더프리미언은 어떠한 경우에도 비밀번호를 알 수 없습니다.
                                        <br />
                                        반드시 비밀번호를 기억해주세요
                                    </span>
                                )}
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label">담당자 연락처</label>
                                <div className="input-group">
                                    <input className="form-control" defaultValue={contact || ""} />
                                    <div className="col-sm-2  edit_save_btn">
                                        <Button data-bs-toggle="modal" data-bs-target="#contactNnbModal">
                                            수정
                                        </Button>
                                        <PhoneEditModal clientId={clientId} setEffect={setEffect} managerName={managerName} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-3">
                            <div className="col-sm-6">
                                <label className="form-label">사업자등록번호</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" defaultValue={registrationNum || ""} />
                                    <div className="col-sm-2  edit_save_btn">
                                        <Button data-bs-toggle="modal" data-bs-target="#businessNnbModal">
                                            수정
                                        </Button>
                                        <CompanyNumberEditModal deforedate={registrationDate} clientId={clientId} setEffect={setEffect} regnumActive={regnumActive} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label">회사 홈페이지</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={companyUrl || ""}
                                        onChange={(e) => setComapnyUrl(e.target.value)}
                                        readOnly={!companyUrlElse}
                                    />
                                    <div className="col-sm-2  edit_save_btn">
                                        {companyUrlElse ? (
                                            <Button
                                                className="save_btn1"
                                                onClick={() => {
                                                    companyUrlUpdate();
                                                    setComapnyUrlElse(false);
                                                }}
                                            >
                                                저장
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setComapnyUrlElse(true)}>수정</Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-3">
                            <div className="col-sm-6">
                                <label className="form-label">회사명</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={companyName || ""}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        readOnly={!companyNameElse}
                                    />
                                    <div className="col-sm-2  edit_save_btn">
                                        {companyNameElse ? (
                                            <Button
                                                className="save_btn1"
                                                onClick={() => {
                                                    companyNameUpdate();
                                                    setCompanyNameElse(false);
                                                }}
                                            >
                                                저장
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setCompanyNameElse(true)}>수정</Button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label">사업 업종</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" defaultValue={category || ""} readOnly />
                                    <div className="col-sm-2  edit_save_btn">
                                        <Button data-bs-toggle="modal" data-bs-target="#businessTypeModal">
                                            수정
                                        </Button>
                                        {companyType && <TypeEditModal clientId={clientId} datas={companyType} setEffect={setEffect} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-3 delete_account_btn">
                            <div className="col-sm-12 text-end">
                                <Button className="" onClick={removeUser}>
                                    계정 삭제
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UserInfo;
