import React, { Fragment, useState } from "react";
import LoginModal from "components/LoginModal/LoginModal";
import Banner from "assets/images/bg.jpg";
import { Link } from "react-router-dom";
import Folding_img from "assets/images/folding_img.png";
import Image1 from "assets/images/image1.png";
import Image2 from "assets/images/image2.png";
import Image3 from "assets/images/image3.png";
import Image4 from "assets/images/image4.png";
import Image5 from "assets/images/image5.png";
import IconImage1 from "assets/images/icon1.png";
import IconImage2 from "assets/images/icon2.png";
import IconImage3 from "assets/images/icon3.png";
import IconImage4 from "assets/images/icon4.png";
import IconImage5 from "assets/images/icon5.png";
import ProcessImage1 from "assets/images/process1.png";
import ProcessImage2 from "assets/images/process2.png";
import ProcessImage3 from "assets/images/process3.png";
import ProcessImage4 from "assets/images/process4.png";
import TopButton from "components/ScrollToTopButton/TopButton";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);

    //Chat box
    const [style, setStyle] = useState("cont");
    ProcessImage1;
    const [visible, setVisible] = useState(false);

    return (
        <Fragment>
            <LoginModal show={modalShow} pathname={"/campaignestimate"} handleClose={handleClose} />
            <section className="main_home">
                <div className="hm_banner">
                    <img src={Banner} alt="Banner" />
                    <div className="floding_effect">
                        {" "}
                        <Link to="/downloadapp" target="_blank">
                            <img src={Folding_img} alt="Folding_Effect" />
                        </Link>
                    </div>
                    <div className="banner_body modal-dialog-centered">
                        <div className="banner_text">
                            <h1>우리 브랜드에 제일 잘 어울리는 인플루언서</h1>
                            <Button className="btn_layout" onClick={() => (loggedIn ? navigate("/campaignestimate") : setModalShow(true))}>
                                캠페인 등록하기
                            </Button>
                            <Link to="/" className="btn_layout" style={{ display: "none" }}>
                                회사 소개서 다운로드
                            </Link>
                        </div>
                    </div>
                </div>
                {/* hm_banner */}

                <div className="benefit_pts">
                    <div className="container">
                        <div className="benefit_pts_dtls  d-flex">
                            <div className="col-sm-6 text_info">
                                <p>찐 개발자가 자식처럼 여기는</p>
                                <h1>
                                    빅데이터 기반
                                    <br />
                                    인플루언서 분석 AI 엔진
                                </h1>
                            </div>
                            <div className="col-sm-6 ">
                                <img className="float_right" src={Image1} />
                            </div>
                        </div>
                        {/* benefit_pts_dtls */}
                        <div className="benefit_pts_dtls  d-flex">
                            <div className="col-sm-6">
                                <img src={Image2} />
                            </div>
                            <div className="col-sm-6  text_info">
                                <p>어려운 직접 컨택 과정이 없는</p>
                                <h1>인플루언서 매칭</h1>
                            </div>
                        </div>
                        {/* benefit_pts_dtls */}
                        <div className="benefit_pts_dtls  d-flex">
                            <div className="col-sm-6  text_info">
                                <p>리얼 인플루언서가 직접 운영하는</p>
                                <h1>
                                    고퀄리티 채널의 <br />
                                    포스팅
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <img className="float_right" src={Image3} />
                            </div>
                        </div>
                        {/* benefit_pts_dtls */}
                        <div className="benefit_pts_dtls  d-flex">
                            <div className="col-sm-6">
                                <img src={Image4} />
                            </div>
                            <div className="col-sm-6  text_info">
                                <p>사장님이 좋아하는</p>
                                <h1 className="fontsize_40">
                                    실시간 캠페인 모니터링을 통한 <br />
                                    신속한 결과 확인
                                </h1>
                            </div>
                        </div>
                        {/* benefit_pts_dtls */}
                        <div className="benefit_pts_dtls  d-flex">
                            <div className="col-sm-6  text_info">
                                <p>마케터가 직접 고른</p>
                                <h1>
                                    투명한 데이터의
                                    <br />
                                    깔끔한 리포트 제공
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <img className="float_right" src={Image5} />
                            </div>
                        </div>
                        {/* benefit_pts_dtls */}
                    </div>
                </div>
                {/* Benefit_pts */}

                <div className="campaign_process">
                    <div className="container">
                        <h1 className="heading">마케팅 캠페인 프로세스</h1>

                        <div className="campaign_process_pts">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="number">
                                                <span>01</span>
                                            </div>
                                            <img src={ProcessImage1} alt="" />
                                            <h4 className="card-title">캠페인 만들기</h4>
                                            <p className="card-text">
                                                원하는 방식대로 <br />
                                                마케팅 캠페인을 설계
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="number">02</div>
                                            <img src={ProcessImage2} alt="" />
                                            <h4 className="card-title">인플루언서 모집 및 선발</h4>
                                            <p className="card-text">
                                                설정한 인플루언서 <br />
                                                모집 및 선발{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="number">03</div>
                                            <img src={ProcessImage3} alt="" />
                                            <h4 className="card-title">
                                                캠페인 포스팅 <br />
                                                실시간 모니터링
                                            </h4>
                                            <p className="card-text">
                                                인플루언서의 포스팅을 <br />
                                                실시간으로 모니터링
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="number">04</div>
                                            <img src={ProcessImage4} alt="" />
                                            <h4 className="card-title">포스팅 평가 및 리포트 확인</h4>
                                            <p className="card-text">
                                                인플루언서의 포스팅 평가와 <br />
                                                캠페인 결과를 깔끔한 리포트로 확인!
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={Image} alt="" />
                                            <div className="number">05</div>
                                            <h4 className="card-title">마케팅 컨설팅</h4>
                                            <p className="card-text">With supporting text below With supporting text below</p>
                                        </div>
                                    </div>
                                 </div> */}
                            </div>
                        </div>
                        {/*campaign_process_pts */}
                    </div>
                </div>
                {/* campaign_process */}

                <div className="success_story" style={{ display: "none" }}>
                    <div className="container-fluid">
                        <h2>마케팅 캠페인 프로세스</h2>

                        <div className="success_story_pts">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={ProcessImage4} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={ProcessImage4} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={ProcessImage4} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={ProcessImage4} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={ProcessImage4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link to="/" className=" custom_btn btn btn-primary">
                                캠페인 등록하기
                            </Link>
                        </div>
                        {/*success_story_pts*/}
                    </div>
                </div>
                {/*success_story*/}
            </section>

            <TopButton />

            {/* Chat message box */}
            <div className="open-button" onClick={() => setVisible(!visible)} style={{ display: "none" }}>
                {!visible ? "Chat" : "Close"}
            </div>
            {visible && (
                <>
                    <div className="chat-popup" id="myForm">
                        <form action="/action_page.php" className="form-container">
                            <h1>Chat</h1>

                            <label htmlFor="msg">
                                <b>Message</b>
                            </label>
                            <textarea placeholder="Type message.." name="msg" required></textarea>

                            <button type="submit" className="btn">
                                Send
                            </button>
                        </form>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default Home;
