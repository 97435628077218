import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDaumPostcodePopup } from "react-daum-postcode";
const CURRENT_URL = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
import { AgeDataList } from "components/db/AgeDataList";
import { locale } from "components/common/common";
import { handleMultiImageChange } from "components/untils/UploadImg";
import { GetMinDate, todayDate } from "components/untils/GetMinDate";
import { regionDataList } from "components/db/DataArrayList";
import DropZone from "components/untils/DropZone";
import moment from "moment";

const RegisterBasicInfo = ({ setActiveTab, jsonData, setJsonData }) => {
    // console.log("jsonData -- ", jsonData);
    const [addressInput, setAddressInput] = useState(jsonData?.sponsor_method?.campaign_address ? jsonData?.sponsor_method?.campaign_address : "");
    const [methodType, setMethodType] = useState("");
    const [dateStart, setDateStart] = useState(jsonData?.campaign_postingperiod[0]?.start_date ? jsonData?.campaign_postingperiod[0]?.start_date : null);
    const [dateEnd, setDateEnd] = useState(jsonData?.campaign_postingperiod[0]?.end_date ? jsonData?.campaign_postingperiod[0]?.end_date : null);
    const [postingStart, setPostingStart] = useState(jsonData?.campaign_postingperiod[1]?.start_date ? jsonData?.campaign_postingperiod[1]?.start_date : null);
    const [postingEnd, setPostingEnd] = useState(jsonData?.campaign_postingperiod[1]?.end_date ? jsonData?.campaign_postingperiod[1]?.end_date : null);
    const [firstCheckbox, setFirstCheckbox] = useState(jsonData?.campaign_regioncondition[0] == "전국" ? true : false);
    const [otherCheckboxes, setOtherCheckboxes] = useState(jsonData?.campaign_regioncondition ? jsonData?.campaign_regioncondition : []);
    const [lastCheck, setLastCheck] = useState(false);
    // const setImgData = Object.values(jsonData?.campaign_product_pictures);
    // 이미지 json 전달
    const [selectFiles, setSelectFiles] = useState([]);
    // 이미지  dropzone 전달
    const [imageFiles, setImageFiles] = useState(jsonData?.campaign_product_preview);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            productName: jsonData?.product_name,
            method: jsonData?.sponsor_method?.sponsor_method,
            address_detail: jsonData?.sponsor_method?.campaign_address_detail,
            gender: jsonData?.gender_condition === "A" ? ["W", "M"] : jsonData?.gender_condition,
            agecondition: jsonData?.agecondition,
        },
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === "전국") {
            setFirstCheckbox(checked);
            setOtherCheckboxes(["전국"]);
            setLastCheck(checked);

            if (!checked) {
                setOtherCheckboxes([]);
                setLastCheck(false);
            }
        } else {
            if (!firstCheckbox) {
                setLastCheck(true);
                setOtherCheckboxes((prevCheckboxes) => {
                    if (checked) {
                        return [...prevCheckboxes, name];
                    } else {
                        return prevCheckboxes.filter((checkbox) => checkbox !== name);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (otherCheckboxes?.length === 0) {
            setLastCheck(false);
        }
    }, [otherCheckboxes]);

    // 모집기간, 포스팅 기간 핸들링
    const handleDateChange = (event, picker) => {
        setDateStart(picker.startDate);
        setDateEnd(picker.endDate);
    };
    const handlePostingDateChange = (event, picker) => {
        setPostingStart(picker.startDate);
        setPostingEnd(picker.endDate);
    };

    const handleForm = (e) => {
        const address_details = document.querySelector(".address_details");
        if (e.target.id === "retrieval1") {
            address_details.style.display = "flex";
            setMethodType("retrieval");
        }
        if (e.target.id === "free1") {
            // console.warn("hi");
            address_details.style.display = "none";
            setMethodType("free");
        }
    };
    const removeWhitespace = (str) => {
        return str.replace(/\s+/g, "");
    };

    // 주소찾기 API
    const open = useDaumPostcodePopup(CURRENT_URL);
    const onClickHandler = () => {
        open({ onComplete: handleComplete });
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        setAddressInput(data.address);
    };

    // 하나라도 체크박스 되어있나 체크
    const validate = (value) => {
        return value?.length > 0 || "체크박스를 하나 이상 선택해주세요.";
    };
    const onSubmit = (data, e) => {
        e.preventDefault();
        let gender = data?.gender?.length === 2 ? "A" : data?.gender[0] === "W" ? "W" : "M";
        // const newStartDate = dateEnd?.clone().add(2, "days");

        // else if (!imageState?.length) {
        //     alert("이미지를 업로드해주세요.");
        // }

        // 벨리데이션
        if (!dateStart || !dateEnd) {
            alert("모집기간을 선택하여 주세요.");
            return false;
        } else if (!postingEnd) {
            alert("포스팅 기간을 선택하여 주세요.");
            return false;
        } else if (data?.method === "retrieval" && !addressInput) {
            alert("주소를 입력하여 주세요.");
            return false;
        } else {
            let setData = {
                product_name: data.productName,
                campaign_postingperiod: [
                    {
                        start_date: moment(dateStart)?.format("YYYY-MM-DD HH:mm:s"),
                        end_date: moment(dateEnd)?.format("YYYY-MM-DD HH:mm:s"),
                        period_type: "인플루언서 모집기간",
                        date_type: "I",
                    },
                    {
                        start_date: moment(postingStart)?.format("YYYY-MM-DD HH:mm:ss"),
                        end_date: moment(postingEnd)?.format("YYYY-MM-DD HH:mm:ss"),
                        period_type: "캠페인 포스팅 기간",
                        date_type: "P",
                    },
                ],
                sponsor_method: {
                    sponsor_method: methodType,
                    discount: "",
                    campaign_address: addressInput,
                    campaign_address_detail: data.address_detail,
                },
                gender_condition: gender,
                agecondition: data?.agecondition,
                campaign_regioncondition: otherCheckboxes,
                campaign_product_pictures: selectFiles,
                campaign_product_preview: imageFiles,
                tabLevel: "moreinfo",
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            // console.log("프리뷰", imageFiles);
            // console.log("확인", selectFiles);
            setJsonData(tempJson);
            setActiveTab("moreinfo");
        }
    };

    const onError = (data) => {
        console.log("onError -- ", data);
        alert("필수 값을 입력해 주세요.");
    };

    const DateComponent = ({ minDate }) => {
        return (
            <DateRangePicker
                onApply={handlePostingDateChange}
                initialSettings={{
                    locale: locale,
                    minDate: minDate,
                }}
            >
                <div className="clander_input">
                    <input type="text" className="form-control" value={postingStart ? moment(postingStart).format("YYYY-MM-DD") : ""} placeholder="포스팅 시작일" readOnly />
                    <input type="text" className="form-control " value={postingEnd ? moment(postingEnd).format("YYYY-MM-DD") : ""} placeholder="포스팅 마감일" readOnly />
                </div>
            </DateRangePicker>
        );
    };

    useEffect(() => {
        const address_details = document.querySelector(".address_details");
        if (jsonData?.sponsor_method?.sponsor_method === "retrieval") {
            address_details.style.display = "flex";
            setMethodType("retrieval");
        }
        if (jsonData?.sponsor_method?.sponsor_method === "free") {
            address_details.style.display = "none";
            setMethodType("free");
        }
    }, []);
    return (
        <Fragment>
            <div className="basic_register_campaign  common_review">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">01</span> 홍보하실 상품명 / 브랜드명 / 서비스명을 입력해 주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input
                                        className="form-control"
                                        {...register("productName", {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">02</span> 캠페인 대표 이미지를 업로드해주세요<span className="required_field">*</span> <br />
                                    <span className="extra_text">App의 캠페인 리스트에서 보여지는 이미지 입니다.</span>
                                </label>
                            </div>
                            <div className="col-sm-12 overlay_file1">
                                <DropZone imageFiles={imageFiles} setImageFiles={setImageFiles} selectFiles={selectFiles} setSelectFiles={setSelectFiles} />
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">03</span> 모집 기간을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <DateRangePicker
                                        onApply={handleDateChange}
                                        initialSettings={{
                                            locale: locale,
                                            minDate: todayDate(),
                                        }}
                                    >
                                        <div className="clander_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={dateStart ? moment(dateStart).format("YYYY-MM-DD") : ""}
                                                placeholder="모집 시작일"
                                                readOnly
                                            />
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : ""}
                                                placeholder="모집 마감일"
                                                readOnly
                                            />
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">04</span> 캠페인 포스팅 기간을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="clander_input">
                                        <DateComponent minDate={GetMinDate(dateEnd, 1)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">05</span> 제품 협찬 방법을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            id="free1"
                                            type="radio"
                                            name="shooting"
                                            value="free"
                                            {...register("mothod", {
                                                validate: validate,
                                                onChange: (e) => handleForm(e),
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="free1">
                                            상품 무료 제공
                                        </label>
                                    </div>

                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="retrieval1"
                                            name="shooting"
                                            value="retrieval"
                                            {...register("mothod", {
                                                validate: validate,
                                                onChange: (e) => handleForm(e),
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="retrieval1">
                                            캠페인 진행 후 상품 회수
                                        </label>

                                        <div className="row address_details" style={{ display: "none" }}>
                                            <div className="col-sm-2">
                                                <Button className="width_100 un_active" style={{ position: "initial", padding: "15px" }} onClick={onClickHandler}>
                                                    주소 찾기
                                                </Button>
                                            </div>
                                            <div className="col-sm-10">
                                                <input className="form-control" placeholder="" defaultValue={addressInput} readOnly />
                                            </div>

                                            <div className="col-sm-12 mt-2">
                                                <input className="form-control" placeholder="상세주소 입력" {...register("address_detail")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7 gender_age">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">06</span> 프리미언(인플루언서) 조건을 알려주세요<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">성별</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    <div className="form-check form-check-inline  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="gender"
                                            value="M"
                                            id="male"
                                            {...register("gender", {
                                                validate: validate,
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="male">
                                            {" "}
                                            남성{" "}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="gender"
                                            value="W"
                                            id="female"
                                            {...register("gender", {
                                                validate: validate,
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="female">
                                            {" "}
                                            여성{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">연령대</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    {AgeDataList?.map((item) => {
                                        return (
                                            <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    id={item.value}
                                                    {...register("agecondition", { validate: validate })}
                                                />
                                                <label className="form-check-label" htmlFor={item.value}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-1 width_10  text-end">
                                    <label className="form-label">지역</label>
                                </div>
                                <div className="col-sm-11 width_90">
                                    <div className="form-check form-check-inline  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="전국"
                                            value="전국"
                                            id="allRegion"
                                            onChange={(e) => handleCheckboxChange(e)}
                                            checked={firstCheckbox}
                                        />
                                        <label className="form-check-label" htmlFor="allRegion">
                                            전국
                                        </label>
                                    </div>
                                    {regionDataList?.map((item) => {
                                        if (item.value !== "전국") {
                                            const isChecked = otherCheckboxes.includes(item.value);
                                            return (
                                                <div className="form-check form-check-inline  mb-3" key={item.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name={item.value}
                                                        value={item.value}
                                                        id={item.value}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        disabled={firstCheckbox && item.value !== "전국"}
                                                        checked={isChecked}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.value}>
                                                        {item.value}
                                                    </label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12 text-center">
                                <Button className={`btn_layout ${isValid ? "" : "un_active"}`} type="submit">
                                    상세정보 입력하기
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};
export default RegisterBasicInfo;
