import { DescriptionFileSave } from "components/untils/UploadFile";
import AxiosPost from "components/untils/AxiosPost";

export const RegisterNew = async (jsonData, navigate, setLoading, userInfo) => {
    // 등록 -> 저장
    // 여기서 active Y T로 구분해서 axios를 구분하는건?
    if (jsonData?.campaign_id) {
        const onbinedArray = jsonData?.campaign_images ? jsonData?.campaign_product_pictures?.concat(jsonData?.campaign_images) : jsonData?.campaign_product_pictures;

        let newImgArray = []; // 이미지 경로 텍스트 배열
        let newFileForm = []; // 이미지 파일 배열
        for (const item of onbinedArray) {
            if (item?.name) {
                newImgArray?.push(`${process.env.REACT_APP_S3_URL}images/campaigns/${jsonData?.campaign_id}/${item?.name}`);
                newFileForm?.push(item);
            } else {
                newImgArray?.push(item);
            }
        }
        let setData = {
            introduction: jsonData?.introduction?.replace(/\n/g, "<br>"),
            text_requirements: jsonData?.text_requirements?.replace(/\n/g, "<br>"),
            active: "Y",
            campaign_product_pictures: newImgArray,
        };
        let tempJson = jsonData;
        for (var key in setData) {
            tempJson[key] = setData[key];
        }

        // 이미 생성된 것 update로
        AxiosPost("webcampaign/update", tempJson)
            .then(async (res) => {
                if (res?.data?.result === "success") {
                    navigate("/campaignpymtdone");
                    for (let i = 0; i < newFileForm?.length; i++) {
                        DescriptionFileSave(newFileForm[i], tempJson?.campaign_id);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        let setData = {
            introduction: jsonData?.introduction?.replace(/\n/g, "<br>"),
            text_requirements: jsonData?.text_requirements?.replace(/\n/g, "<br>"),
            active: "Y",
        };
        let tempJson = jsonData;
        for (var key in setData) {
            tempJson[key] = setData[key];
        }
        const onbinedArray = tempJson?.campaign_images ? tempJson?.campaign_product_pictures?.concat(tempJson?.campaign_images) : tempJson?.campaign_product_pictures;
        const firstItem = onbinedArray[0]; // 첫번째 건들이지 않기 위해 따로 저장
        const filterArray = onbinedArray?.filter((item, index) => {
            if (index > 0 && item?.name === firstItem?.name) {
                return false;
            } else {
                return true;
            }
        });
        AxiosPost("webcampaign/create", tempJson)
            .then(async (res) => {
                if (res?.data?.result === "success") {
                    await AxiosPost("mail/campaignStart", {
                        name: userInfo?.manager_name,
                        campaignName: jsonData.campaign_name,
                        email: userInfo?.email,
                    });
                    navigate("/campaignpymtdone");

                    let axiosCampaignId = res?.data?.data?.rows?.insertId;

                    for (let i = 0; i < filterArray?.length; i++) {
                        let imgDB = process.env.REACT_APP_S3_URL + "images/campaigns/" + axiosCampaignId + "/" + filterArray[i].name;
                        await AxiosPost("webcampaign/imgCreate", {
                            campaign_id: axiosCampaignId,
                            image_data: imgDB,
                        }).then((res) => {
                            DescriptionFileSave(filterArray[i], axiosCampaignId);
                            setLoading(false);
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
};

export const RegisterTemporay = async (jsonData, navigate) => {
    try {
        // 등록 -> 저장
        // 여기서 최초인지 그 후 인지 구분해서 axios를 넣는건? campaign_id로 구분
        if (jsonData?.campaign_id) {
            // 이미지들을 모두 배열로 합친다. 중복된 데이터는 제거한다.
            const onbinedArray = jsonData?.campaign_images ? jsonData?.campaign_product_pictures?.concat(jsonData?.campaign_images) : jsonData?.campaign_product_pictures;

            let newImgArray = []; // 이미지 경로 텍스트 배열
            let newFileForm = []; // 이미지 파일 배열
            for (const item of onbinedArray) {
                if (item?.name) {
                    newImgArray?.push(`${process.env.REACT_APP_S3_URL}images/campaigns/${jsonData?.campaign_id}/${item?.name}`);
                    newFileForm?.push(item);
                } else {
                    newImgArray?.push(item);
                }
            }
            let setData = {
                introduction: jsonData?.introduction?.replace(/\n/g, "<br>"),
                text_requirements: jsonData?.text_requirements?.replace(/\n/g, "<br>"),
                active: "T",
                campaign_product_pictures: newImgArray,
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }

            // 이미 생성된 것 update로
            AxiosPost("webcampaign/update", tempJson)
                .then(async (res) => {
                    if (res?.data?.result === "success") {
                        navigate("/temporaycampaign");
                        for (let i = 0; i < newFileForm?.length; i++) {
                            DescriptionFileSave(newFileForm[i], tempJson?.campaign_id);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let setData = {
                introduction: jsonData?.introduction?.replace(/\n/g, "<br>"),
                text_requirements: jsonData?.text_requirements?.replace(/\n/g, "<br>"),
                active: "T",
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            const onbinedArray = tempJson?.campaign_images ? tempJson?.campaign_product_pictures?.concat(tempJson?.campaign_images) : tempJson?.campaign_product_pictures;
            const firstItem = onbinedArray[0]; // 첫번째 건들이지 않기 위해 따로 저장
            const filterArray = onbinedArray?.filter((item, index) => {
                if (index > 0 && item?.name === firstItem?.name) {
                    return false;
                } else {
                    return true;
                }
            });
            // 생성해야함
            AxiosPost("webcampaign/create", tempJson)
                .then(async (res) => {
                    if (res?.data?.result === "success") {
                        navigate("/temporaycampaign");
                        let axiosCampaignId = res?.data?.data?.rows?.insertId;
                        for (let i = 0; i < filterArray?.length; i++) {
                            let imgDB = process.env.REACT_APP_S3_URL + "images/campaigns/" + axiosCampaignId + "/" + filterArray[i].name;
                            await AxiosPost("webcampaign/imgCreate", {
                                campaign_id: axiosCampaignId,
                                image_data: imgDB,
                            })
                                .then((res) => {
                                    DescriptionFileSave(filterArray[i], axiosCampaignId);
                                    // setLoading(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    } catch (error) {
        console.log(error);
    }
};
