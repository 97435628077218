import React from "react";
import { ResponsiveBar } from "@nivo/bar";

function ReelsBarGraph() {
    let data = [
        {
            result: "before",
            item: 80,
        },
        {
            result: "after",
            item: 50,
        },
    ];

    const CustomBar = (props) => {
        let data = props?.bar;
        return (
            <rect
                x={data?.data?.indexValue == "before" ? data?.x : data?.x + 15}
                y={data?.y}
                width={80}
                height={data?.height}
                fill={data?.data?.indexValue == "before" ? "#4e78e8" : "#cfcfcf"}
                rx={10}
                ry={10}
            />
        );
    };
    return (
        <div style={{ width: "220px", height: "115px" }}>
            <ResponsiveBar
                data={data}
                keys={["item"]}
                indexBy="result"
                groupMode="grouped"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                borderRadius={8}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridY={false}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                isInteractive={false}
                barComponent={(props) => <CustomBar {...props} />}
                theme={{
                    fontSize: 13,
                    fontFamily: "sans-serif",
                    textColor: "#000000",
                    axis: {
                        fontSize: "13px",
                        tickColor: "#000000",
                        ticks: {
                            line: {
                                stroke: "#000000",
                            },
                            text: {
                                fill: "#000000",
                            },
                        },
                    },
                }}
                legends={[]}
                role="application"
                ariaLabel="Nivo bar chart demo"
            />
        </div>
    );
}

export default ReelsBarGraph;
