import React, { useEffect } from "react";
import ChannelService from "ChannelService";
import { useSelector } from "react-redux";

function ChannelTalk() {
    // ChannelService.loadScript();

    // ChannelService.boot({
    //     pluginKey: process.env.REACT_APP_CHANNEL_TALK_API_KEY,
    // });

    let setting = {
        pluginKey: process.env.REACT_APP_CHANNEL_TALK_API_KEY,
    };

    useEffect(() => {
        delete window.ChannelIO;

        ChannelService.loadScript();

        ChannelService.boot(setting);
    }, []);

    return <></>;
}

export default ChannelTalk;
