import React, { Fragment } from "react";
import Banner from "assets/images/landing_page_bg.jpg";
import GooglePlayBtn from "assets/images/google_play_btn.png";
import AppStoreyBtn from "assets/images/appStore_btn.png";
import AppleBarcord from "assets/images/apple_barcode.png";
import GoogleBarcord from "assets/images/google_barcode.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function LandingPage() {
    return (
        <Fragment>
            <section className="main_home landing_page">
                <div className="hm_banner">
                    <img src={Banner} alt="Banner" />

                    <div className="banner_body modal-dialog-centered">
                        <div className="banner_text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1>
                                            내 취향이
                                            <br />
                                            수익이 됩니다
                                        </h1>
                                    </div>

                                    <div className="col-md-6 app_btns">
                                        <Link to="https://play.google.com/store/apps/details?id=com.ipcx.samplelife&hl=ko" target="_blank">
                                            <img src={GooglePlayBtn} alt="GooglePlay" />
                                        </Link>
                                        <div className="col-md-12 bar_code  text-center">
                                            <img src={GoogleBarcord} alt="GoogleBarcode" />
                                        </div>
                                    </div>

                                    <div className="col-md-6 app_btns">
                                        <Link to="https://apps.apple.com/kr/app/thepremian/id1638049644" target="_blank">
                                            <img src={AppStoreyBtn} alt="GooglePlay" />
                                        </Link>
                                        <div className="col-md-12 bar_code text-center">
                                            <img src={AppleBarcord} alt="AppleBarcode" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hm_banner */}

                {/*<div className="findresults">
                    <p>더프리미언에 등록된 아이디는 총 개입니다.</p>
                   
                            <div className="row findresults_show">
                                <p className="col-sm-12 col-form-label text-cente"><span className="left">David</span><span className="right">ghdwlgh0613@naver.com</span></p>
                                <p className="col-sm-12 col-form-label text-center"><span className="left">Haylie</span><span className="right">heesoo.sim@ethan-alice.com</span></p>
                                <p className="col-sm-12 col-form-label text-center"><span className="left">Haylie</span><span className="right">bksky01@naver.com</span></p>
                            </div>
                        
    </div>*/}
            </section>
        </Fragment>
    );
}

export default LandingPage;
