import React from "react";
import { ResponsiveBar } from "@nivo/bar";

function FollowerRegionBarGraph(cityData) {
    let data = cityData?.cityData;

    const CustomBar = (props) => {
        let data = props?.bar;
        // console.log("followerGraph -- ", data);
        return <rect x={data?.x} y={data?.y} width={8} height={data?.height} fill={"#4e78e8"} rx={4} ry={4} />;
    };

    return (
        <div style={{ width: "205px", height: "140px" }}>
            <ResponsiveBar
                data={data}
                keys={["value"]}
                indexBy="key"
                padding={0.4}
                groupMode="grouped"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={() => "#4e78e8"}
                margin={{ bottom: 40 }}
                fill={[
                    {
                        match: {
                            id: "fries",
                        },
                        id: "dots",
                    },
                    {
                        match: {
                            id: "sandwich",
                        },
                        id: "lines",
                    },
                ]}
                borderRadius={8}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 32,
                    truncateTickAt: 0,
                }}
                axisLeft={false}
                enableLabel={false}
                barComponent={(props) => <CustomBar {...props} />}
                theme={{
                    fontSize: 11,
                    fontFamily: "sans-serif",
                    textColor: "#000000",

                    axis: {
                        fontSize: "11px",
                        tickColor: "#000000",
                        ticks: {
                            line: {
                                stroke: "#000000",
                            },
                            text: {
                                fill: "#000000",
                                textAnchor: "center",
                            },
                        },
                    },
                }}
                legends={[]}
                role="application"
                ariaLabel="Nivo bar chart demo"
            />
        </div>
    );
}

export default FollowerRegionBarGraph;
