import React from "react";
import { ResponsivePie } from "@nivo/pie";

function AdRateGraph({ adRate }) {
    let data = [
        {
            id: "여성",
            label: "여성",
            value: 100 - adRate,
            color: "#cfcfcf",
        },
        {
            id: "남성",
            label: "남성",
            value: adRate,
            color: "#4e78e8",
        },
    ];

    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
        let total = 0;
        dataWithArc.forEach((datum) => {
            total += datum.value;
        });

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                }}
            >
                {adRate}%
            </text>
        );
    };

    return (
        <div style={{ width: "300px", height: "180px" }}>
            <ResponsivePie
                data={data}
                innerRadius={0.6}
                padAngle={2}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                colors={(e) => e.data.color}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                enableArcLabels={false}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                fill={[
                    {
                        match: {
                            id: "여성",
                        },
                        id: "여성",
                    },
                    {
                        match: {
                            id: "남성",
                        },
                        id: "남성",
                    },
                ]}
                // layers={() => <insideText />}
                layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
                isInteractive={false}
                animate={false}
                legends={[]}
            />
        </div>
    );
}

export default AdRateGraph;
